<template>
  <footer class="dsf-ppt-footer">
    <div class="dsf-ppt-footer-left">
      <div v-if="multiple" class="dsf-ppt-footer-item">
        <i class="iconfont icon-zhidi"></i> 图片
        第{{ $ppt.activeIndex + 1 }}张，共{{ imgList.length }}张
      </div>
    </div>
    <div class="dsf-ppt-footer-right">
      <div v-if="$ppt.copyNode" class="dsf-ppt-footer-item">
        <el-dropdown size="mini" placement="top" @command="$ppt.copyNode = null">
          <span style="cursor: pointer"><i class="iconfont icon-gongdanqueren"></i> 粘贴板</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>清空</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-if="hasScale" class="dsf-ppt-footer-item">
        <i class="iconfont icon-dingwei"></i>
        缩放: <el-dropdown size="mini" placement="top" @command="doScale">
          <span class="bt">{{ scale }}%</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="num in scaleConfig" :key="num" :command="num">{{ num }}%</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="dsf-ppt-footer-item">
        <i class="iconfont icon-mian"></i>
        尺寸: <span class="bt" @click="$ppt.setting()">{{ width }}</span>
        x <span class="bt" @click="$ppt.setting()">{{ height }}</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "PptFooter",
  inject: ['$ppt'],
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scaleConfig: [30, 40, 50, 75, 100, 125, 150, 200]
    }
  },
  computed: {
    imgList() {
      return this.$ppt.imgList;
    },
    hasScale() {
      return this.$ppt.activeImg !== null;
    },
    width() {
      return this.$ppt.project?.width || 0;
    },
    height() {
      return this.$ppt.project?.height || 0;
    },
    scale() {
      return this.$ppt.scale;
    },
  },
  methods: {
    doScale(num) {
      this.$ppt.$refs.main.setScale(num);
    }
  }
}
</script>