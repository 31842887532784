var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "dsf-ppt-header" },
    [
      _c(
        "div",
        { staticClass: "dsf-ppt-header-left" },
        [
          _vm._l(_vm.buttons, function (button) {
            return _c(
              "dsf-button",
              {
                key: button.id,
                attrs: {
                  disabled: _vm.$ppt.isDesign,
                  title: button.text,
                  icon: button.icon,
                  "btn-style": "icon-text",
                },
                on: {
                  click: function ($event) {
                    return button.handler()
                  },
                },
              },
              [_vm._v(" " + _vm._s(button.text) + " ")]
            )
          }),
          _c("i", { staticClass: "division iconfont icon-vertical_line" }),
          _vm._l(_vm.buttons2, function (button) {
            return _c(
              "dsf-button",
              {
                key: button.id,
                attrs: {
                  disabled: _vm.$ppt.isDesign,
                  title: button.text,
                  icon: button.icon,
                  "btn-style": "icon-text",
                },
                on: {
                  click: function ($event) {
                    return button.handler()
                  },
                },
              },
              [_vm._v(" " + _vm._s(button.text) + " ")]
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "dsf-ppt-header-right" },
        [
          _c(
            "el-checkbox",
            {
              attrs: { size: "mini", border: "" },
              model: {
                value: _vm.$ppt.showRuler,
                callback: function ($$v) {
                  _vm.$set(_vm.$ppt, "showRuler", $$v)
                },
                expression: "$ppt.showRuler",
              },
            },
            [_vm._v("刻度")]
          ),
          _c(
            "el-checkbox",
            {
              attrs: { size: "mini", border: "" },
              model: {
                value: _vm.$ppt.showMousePoint,
                callback: function ($$v) {
                  _vm.$set(_vm.$ppt, "showMousePoint", $$v)
                },
                expression: "$ppt.showMousePoint",
              },
            },
            [_vm._v("辅助线")]
          ),
        ],
        1
      ),
      !_vm.$ppt.isDesign
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.dialogVisible,
                "custom-class": "dsf-ppt-help",
                width: "800px",
                title: "操作指南",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [_c("ppt-help")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }