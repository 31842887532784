<template>
  <div class="dsf-ppt-tool-section">
    <div class="dsf-ppt-tool-title">富文本属性</div>
    <dsf-virtual-scroll height="0">
      <base-attrs-item ref="base" @change="drawText" />
      <fill-attrs-item ref="fill" />
      <shadow-attrs-item ref="shadow" />
      <stroke-attrs-item ref="stroke" />
    </dsf-virtual-scroll>
  </div>
</template>

<script>
import mixins from './mixins';

export default {
  name: "PptRichTextAttr",
  mixins: [mixins],
  methods: {
    drawText() {
      this.activeShape.htmlToImage();
    }
  }
}
</script>