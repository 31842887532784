import PptITextEditor from './PptITextEditor.js';

export default function () {
  class IText extends window.Konva.Text{
    constructor(config) {
      super(config);
      this.on('dblclick', this.#dblclick);
    }

    #dblclick() {
      _.each(this.getStage().find('Transformer'), t => t.destroy());
      new PptITextEditor(this);
    }
  }

  IText.prototype.className = 'IText';
  window.Konva['IText'] = IText;
}