var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-ppt-tool-section dsf-ppt-layer-tree" },
    [
      _c("div", { staticClass: "dsf-ppt-tool-title" }, [_vm._v("图层")]),
      _c(
        "dsf-virtual-scroll",
        { attrs: { height: "0" } },
        [
          _c("el-tree", {
            ref: "tree",
            attrs: {
              data: _vm.treeData,
              "expand-on-click-node": false,
              "allow-drop": _vm.allowDrop,
              "node-key": "_id",
              "highlight-current": "",
              "check-on-click-node": "",
              "default-expand-all": "",
              draggable: "",
            },
            on: {
              "current-change": _vm.currentChange,
              "node-drop": _vm.nodeDrop,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var node = ref.node
                  var data = ref.data
                  return [
                    (
                      data.className !== "Transformer" && data.attrs.id !== "bg"
                        ? true
                        : (node.visible = false)
                    )
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "context-menu",
                                rawName: "v-context-menu",
                                value: { params: data, data: _vm.treeMenu },
                                expression: "{params: data, data: treeMenu }",
                              },
                            ],
                            staticClass: "custom-tree-node",
                          },
                          [
                            data.children !== undefined
                              ? [
                                  _c("i", {
                                    staticClass: "iconfont",
                                    class: [
                                      node.expanded
                                        ? "icon-dakai"
                                        : "icon-wenjianjia",
                                    ],
                                  }),
                                  _c("span", [_vm._v(_vm._s(data.className))]),
                                ]
                              : [
                                  _c("i", {
                                    staticClass: "iconfont",
                                    class: [_vm.iconConfig[data.className]],
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.nameConfig[data.className]) +
                                        " "
                                    ),
                                    _c("i", { staticClass: "node-id" }, [
                                      _vm._v(_vm._s(data._id)),
                                    ]),
                                  ]),
                                ],
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }