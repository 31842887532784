import utilsControl from './utils';
import utils from './utils/utils';
import * as control from './control';

const components = [
  ...Object.values(utilsControl),
  ...Object.values(control)
];

function install(Vue) {
  dsf.ppt = {
    // 导出blob对象
    export: utils.exportByData,
    // 下载图片
    download: utils.downloadByData,
  };

  components.forEach((it) => {
    Vue.component(it.name, it);
    if (!window.$components['ppt']) {
      window.$components['ppt'] = {}
    }
    window.$components['ppt'][it.name] = it;
  });
}
export default install;

export { install };
