var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "sliderBox",
      staticClass: "dsf-ppt-color-select-alpha",
      on: { mousedown: _vm.mousedown },
    },
    [
      _c("div", {
        staticClass: "dsf-ppt-color-select-alpha__bar",
        style: _vm.alphaStyle,
      }),
      _c("span", {
        ref: "thumb",
        staticClass: "slider",
        style: _vm.sliderStyle,
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }