var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-ppt-img-library" },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            action: _vm.action,
            "show-file-list": false,
            "before-upload": _vm.beforeUpload,
            accept: ".jpg,.png,.jpeg,.gif",
            "append-to-body": "",
            drag: "",
            multiple: "",
          },
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v("将图片拖到此处，或"),
            _c("em", [_vm._v("点击上传")]),
          ]),
        ]
      ),
      _c("div", { staticClass: "title" }, [_vm._v("上传历史")]),
      _c(
        "div",
        { staticClass: "history" },
        [
          _c(
            "dsf-virtual-scroll",
            { attrs: { width: "100%" } },
            [
              !_vm.history.length
                ? _c("div", { staticClass: "no-data" }, [
                    _vm._v("暂无上传历史"),
                  ])
                : _vm._e(),
              _vm._l(_vm.history, function (item, index) {
                return [
                  _c(
                    "div",
                    { key: index, staticClass: "history-item" },
                    [
                      _c("el-image", {
                        attrs: {
                          src: _vm._f("imgFilter")(item),
                          "preview-src-list": _vm.srcList,
                          fit: "cover",
                          lazy: "",
                        },
                      }),
                      item.status
                        ? _c(
                            "div",
                            {
                              staticClass: "status",
                              attrs: {
                                title: item.status === "fail" ? "上传失败" : "",
                              },
                            },
                            [
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.status === "wait",
                                    expression: "item.status === 'wait'",
                                  },
                                ],
                                staticClass: "el-icon-loading",
                              }),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.status === "fail",
                                    expression: "item.status === 'fail'",
                                  },
                                ],
                                staticClass: "el-icon-error",
                              }),
                            ]
                          )
                        : _vm.multiple
                        ? _c("el-checkbox", {
                            attrs: {
                              value: _vm.selectIds.indexOf(item.id) > -1,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.itemClickMultiple(item)
                              },
                            },
                          })
                        : _c("el-checkbox", {
                            attrs: {
                              value:
                                _vm.selectItem && _vm.selectItem.id === item.id,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.itemClick(item)
                              },
                            },
                          }),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }