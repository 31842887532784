var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf-ppt-color-slider" }, [
    _c(
      "div",
      { ref: "sliderBox", staticClass: "dsf-ppt-color-slider__bar" },
      [
        _c("div", {
          staticClass: "dsf-ppt-color-slider__bar--bg",
          style: _vm.paneStyle,
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.addPoint.apply(null, arguments)
            },
          },
        }),
        _vm._l(_vm.value, function (poi, i) {
          return [
            _c(
              "div",
              {
                key: i,
                staticClass: "dsf-ppt-color-slider__point",
                style: { left: poi.length + "%" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: poi.active,
                        expression: "poi.active",
                      },
                    ],
                    staticClass: "tips",
                  },
                  [_vm._v(_vm._s(poi.length))]
                ),
                _c(
                  "el-popover",
                  {
                    ref: "popover",
                    refInFor: true,
                    attrs: {
                      tabindex: 1,
                      placement: "bottom",
                      width: "300",
                      trigger: "manual",
                    },
                    model: {
                      value: poi.show,
                      callback: function ($$v) {
                        _vm.$set(poi, "show", $$v)
                      },
                      expression: "poi.show",
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          slot: "reference",
                          width: "14",
                          height: "18",
                          viewBox: "-7 0 14 18",
                        },
                        slot: "reference",
                      },
                      [
                        _c("polygon", {
                          attrs: {
                            fill: poi.color,
                            points: "0,2 -6,8 -6,17 6,17 6,8",
                          },
                          on: {
                            mousedown: function ($event) {
                              return _vm.pointMousedown(poi, $event)
                            },
                            mouseup: function ($event) {
                              return _vm.showColorPop(poi, i, $event)
                            },
                          },
                        }),
                      ]
                    ),
                    _c("dsf-ppt-color-select", {
                      staticStyle: { padding: "0" },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("change", _vm.value)
                        },
                      },
                      model: {
                        value: poi.color,
                        callback: function ($$v) {
                          _vm.$set(poi, "color", $$v)
                        },
                        expression: "poi.color",
                      },
                    }),
                  ],
                  1
                ),
                _vm.value.length > 2
                  ? _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !poi.active,
                          expression: "!poi.active",
                        },
                      ],
                      staticClass: "remove iconfont icon-guanbi2",
                      attrs: { title: "删除" },
                      on: {
                        click: function ($event) {
                          return _vm.removePoint(i)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }