<template>
  <div class="ds-control ds-form-item dsf-ppt-poster" :class="getCss">
    <label v-if="showLabel" class="ds-form-label" :style="getLabelWidthStyle()">
      {{ label }}
      <i v-if="dataCapion" class="iconfont icon-bangzhuzhongxin ds-control-helper" @click="showHelper"></i>
    </label>
    <div class="ds-form-block" :style="getFormItemBlockMarginLeft()">
      <dsf-button v-if="readOnly && simple" :disabled="!imgList.length" title="点击预览" btn-style="icon-text" @click="imagePreview(0)">
        共{{ imgList.length }}张图片
      </dsf-button>
      <div v-else-if="!imgList.length" class="dsf-ppt-poster-imgList">
        <div v-if="readOnly || disabled" class="dsf-ppt-poster-imgItem emit-data" :style="imgStyle">
          <span>暂无图片</span>
        </div>
        <div v-else class="dsf-ppt-poster-imgItem emit-bt" @click="editor()">
          <i class="iconfont icon-add" :style="imgStyle"></i>
        </div>
      </div>
      <div v-else-if="!isFold" class="dsf-ppt-poster-imgList">
        <div v-for="({img}, i) in imgList" :key="i" class="dsf-ppt-poster-imgItem">
          <dsf-image :src="img" :style="imgStyle" />
          <div class="dsf-ppt-poster-imgItem-mask">
            <dsf-button title="预览" size="small" btn-style="2" icon="icon_yulan" @click="imagePreview(i)"></dsf-button>
            <dsf-button v-if="!readOnly && !disabled" title="编辑" size="small" btn-style="2" icon="tianxie" @click="editor(i)"></dsf-button>
            <dsf-button v-if="!readOnly && !disabled" title="删除" size="small" btn-style="2" icon="shanchu" @click="remove(i)"></dsf-button>
          </div>
        </div>
        <div v-if="multiple && !isFold && !readOnly && !disabled && imgList.length < max" class="dsf-ppt-poster-imgItem emit-bt" @click="editor()">
          <i class="iconfont icon-add" :style="imgStyle"></i>
        </div>
      </div>
      <div v-else class="dsf-ppt-poster-imgList" :class="{fold: imgList.length > 1}">
        <div class="dsf-ppt-poster-imgItem">
          <dsf-image :src="imgList[0].img" :style="imgStyle" />
          <div class="dsf-ppt-poster-imgItem-mask">
            <dsf-button title="预览" size="small" btn-style="2" icon="icon_yulan" @click="imagePreview(0)"></dsf-button>
            <dsf-button v-if="!readOnly && !disabled" title="编辑" size="small" btn-style="2" icon="tianxie" @click="editor(0)"></dsf-button>
            <dsf-button v-if="!readOnly && !disabled" title="删除" size="small" btn-style="2" icon="shanchu" @click="remove(0)"></dsf-button>
          </div>
        </div>
      </div>
      <slot name="error">
        <div v-if="errors.length > 0" class="ds-error-text">
          {{ errorsMsg }}
        </div>
      </slot>
    </div>
    <el-dialog
      v-if="!isDesign && !readOnly"
      :visible.sync="showDialog"
      :title="label"
      :close-on-click-modal="false"
      custom-class="dsf-ppt-poster-dialog"
      modal-append-to-body
      append-to-body
      fullscreen
      @opened="opened = true">
      <template v-if="opened">
        <dsf-ppts-setting v-if="multiple && isFold" v-bind="options" @ready="pptReady" @save="pptSave" />
        <dsf-ppt-setting v-else v-bind="options" @ready="pptReady" @save="pptSave" />
      </template>
    </el-dialog>
  </div>
</template>

<script>
import utils from '../utils/utils';

const maxSize = 240;
const minSize = 180;

export default dsf.component({
  name: "DsfPptPoster",
  ctrlCaption: "海报设计",
  mixins: [$mixins.formControl],
  props: {
    value: {
      type: [Array, Object],
      default: null
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get("dsf-ppt-poster-meta-data");
      },
    },
    // 是否生成多张
    multiple: {
      type: Boolean,
      default: false
    },
    // 是否折叠展示
    isFold: {
      type: Boolean,
      default: false
    },
    // 生成图片数量（最少）
    min: {
      type: Number,
      default: 0
    },
    // 生成图片数量（最多）
    max: {
      type: Number,
      default: 20
    },
    // 生成图片宽度
    width: {
      type: Number,
      default: 800
    },
    // 生成图片高度
    height: {
      type: Number,
      default: 600
    },
    // 生成图片格式
    mimeType: {
      type: String,
      default: 'image/jpeg'
    },
    // 生成图片质量(JPEG格式有效)
    quality: {
      type: Number,
      default: 0.75
    },
    // 启用文本代理输出
    textProxy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDialog: false,
      opened: false,
      activeIndex: 0
    }
  },
  computed: {
    options() {
      let res = {
        propsWidth: this.width,
        propsHeight: this.height,
        propsMimeType: this.mimeType,
        propsQuality: this.quality,
        // 是否禁用配置框
        propsDisabledConfig: true,
        isDesignCtrl: true
      };
      if (this.multiple && this.isFold) {
        res.propsMin = this.min;
        res.propsMax = this.max;
      }
      return res;
    },
    imgList() {
      if (this.isDesign) {
        return this.multiple ? [{img: ''}, {img: ''}, {img: ''}] : [{img: ''}];
      }
      let val = this.value;
      if (!val || val.length == 0) {
        return [];
      }
      if (dsf.isObject(val)) {
        return val.config ? [val] : [];
      }
      if (dsf.isArray(val)) {
        return val;
      }
      return [];
    },
    imgStyle() {
      let w = this.width, h = this.height;
      let k = w / h, width = w, height = h;
      if (width > maxSize) {
        width = maxSize;
        height = maxSize / k;
      }
      if (height > maxSize) {
        height = maxSize;
        width = maxSize * k;
      }
      if (width < minSize) {
        width = minSize;
      }
      if (height < minSize) {
        height = minSize;
      }
      return {
        width: width + 'px',
        height: height + 'px',
      }
    },
    previewSrcList() {
      return this.isDesign ? null : _.map(this.imgList, 'img');
    }
  },
  watch: {
    showDialog(to) {
      if (to && this._pptVm) {
        this._pptVm.importLocal(this.getPptConfig());
      }
    }
  },
  created() {
    this._files = {};
  },
  methods: {
    getPptConfig(index = this.activeIndex) {
      let imgList = this.imgList;
      let uploadHistoryList = [];
      let project = {
        width: this.width,
        height: this.height,
        mimeType: this.mimeType,
        quality: this.quality
      };
      if (!this.multiple) {
        project.img = imgList.length ? JSON.parse(imgList[0].config) : null;
      } else if (this.isFold) {
        project.imgList = _.map(imgList, it => JSON.parse(it.config));
      } else {
        project.img = imgList.length > this.activeIndex ? JSON.parse(imgList[index].config) : null;
      }
      return {project, uploadHistoryList};
    },
    imagePreview(startPosition) {
      dsf.layer.imagePreview({
        images: this.previewSrcList,
        startPosition
      });
    },
    editor(i = this.imgList.length) {
      this.activeIndex = i;
      this.showDialog = true;
    },
    remove(index) {
      if (this.multiple) {
        this.value.splice(index, 1);
      } else {
        this.emitValueChange({
          data: '',
          img: ''
        });
      }
    },
    pptReady(params) {
      this._pptVm = params.target;
      if (this.imgList.length) {
        this._pptVm.importLocal(this.getPptConfig());
      }
    },
    pptSave({args: {file, files, json: {project}}}) {
      if (!this.multiple) {
        this.emitValueChange({
          config: JSON.stringify(project.img),
          img: URL.createObjectURL(file)
        });
        this._files = {0: file};
      } else if (!this.isFold) {
        let item = {
          config: JSON.stringify(project.img),
          img: URL.createObjectURL(file)
        };
        if (this.activeIndex == this.imgList.length) {
          if (this.value && this.imgList.length) {
            this.value.push(item);
          } else {
            this.emitValueChange([item]);
          }
        } else {
          this.$set(this.value, this.activeIndex, item);
        }
        this._files[this.activeIndex] = file;
      } else {
        let imgList = project.imgList;
        let val = _.map(files, (f, i) => {
          return {
            config: JSON.stringify(imgList[i]),
            img: URL.createObjectURL(f)
          }
        });
        this.emitValueChange(val);
        _.each(files, (f, i) => {
          this._files[i] = f;
        });
      }
      this.showDialog = false;
    },
    clearValue() {
      this.emitValueChange({
        data: '',
        img: ''
      });
    },
    postBefore() {
      if (this.readOnly) {
        return;
      }
      let multiple = this.multiple;
      let val = this.value;
      if (!val || (multiple && !val.length)) {
        this.emitValueChange({
          config: '',
          img: ''
        });
        return;
      }
      if (!Object.values(this._files).length) {
        return;
      }
      if (!multiple) {
        if (val.length) {
          val = val[0];
        }
        return new Promise((resolve, reject) => {
          utils.uploadFile.call(this, this._files[0]).then(res => {
            let img = res.relativePath;
            let {config} = val;
            this.emitValueChange({config, img});
            resolve();
            this._files = {};
          }).catch(err => reject(err));
        });
      }
      if (!dsf.isArray(val)) {
        val = [val];
      }
      return new Promise((resolve, reject) => {
        uploadFiles.call(this, val, this._files, resolve, reject);
      });
    }
  }
});

async function uploadFiles(val, files, resolve, reject) {
  for (let i = 0, l = val.length; i < l; i++) {
    let it = val[i];
    let file = files[i];
    if (file) {
      try {
        let {relativePath} = await utils.uploadFile.call(this, file);
        it.img = relativePath;
        delete this._files[i];
      } catch (e) {
        reject(e);
        return;
      }
    }
  }
  this._files = {};
  this.emitValueChange(val);
  resolve();
}
</script>