var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "dsf-ppt-footer" }, [
    _c("div", { staticClass: "dsf-ppt-footer-left" }, [
      _vm.multiple
        ? _c("div", { staticClass: "dsf-ppt-footer-item" }, [
            _c("i", { staticClass: "iconfont icon-zhidi" }),
            _vm._v(
              " 图片 第" +
                _vm._s(_vm.$ppt.activeIndex + 1) +
                "张，共" +
                _vm._s(_vm.imgList.length) +
                "张 "
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "dsf-ppt-footer-right" }, [
      _vm.$ppt.copyNode
        ? _c(
            "div",
            { staticClass: "dsf-ppt-footer-item" },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { size: "mini", placement: "top" },
                  on: {
                    command: function ($event) {
                      _vm.$ppt.copyNode = null
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { cursor: "pointer" } }, [
                    _c("i", { staticClass: "iconfont icon-gongdanqueren" }),
                    _vm._v(" 粘贴板"),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [_c("el-dropdown-item", [_vm._v("清空")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.hasScale
        ? _c(
            "div",
            { staticClass: "dsf-ppt-footer-item" },
            [
              _c("i", { staticClass: "iconfont icon-dingwei" }),
              _vm._v(" 缩放: "),
              _c(
                "el-dropdown",
                {
                  attrs: { size: "mini", placement: "top" },
                  on: { command: _vm.doScale },
                },
                [
                  _c("span", { staticClass: "bt" }, [
                    _vm._v(_vm._s(_vm.scale) + "%"),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.scaleConfig, function (num) {
                      return _c(
                        "el-dropdown-item",
                        { key: num, attrs: { command: num } },
                        [_vm._v(_vm._s(num) + "%")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "dsf-ppt-footer-item" }, [
        _c("i", { staticClass: "iconfont icon-mian" }),
        _vm._v(" 尺寸: "),
        _c(
          "span",
          {
            staticClass: "bt",
            on: {
              click: function ($event) {
                return _vm.$ppt.setting()
              },
            },
          },
          [_vm._v(_vm._s(_vm.width))]
        ),
        _vm._v(" x "),
        _c(
          "span",
          {
            staticClass: "bt",
            on: {
              click: function ($event) {
                return _vm.$ppt.setting()
              },
            },
          },
          [_vm._v(_vm._s(_vm.height))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }