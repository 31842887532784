var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf-ppt-color-select" }, [
    _c(
      "div",
      { staticClass: "dsf-ppt-color-select-main" },
      [_c("panel", { ref: "panel" }), _c("hue", { ref: "hue" })],
      1
    ),
    _c(
      "div",
      { staticClass: "dsf-ppt-color-select-main" },
      [_vm.showAlpha ? _c("alpha", { ref: "alpha" }) : _vm._e()],
      1
    ),
    _c("div", { staticClass: "dsf-ppt-color-select-input" }, [
      _c(
        "label",
        [
          _c("span", [_vm._v("R")]),
          _c("el-input-number", {
            attrs: {
              value: _vm.rgba[0],
              min: 0,
              max: 255,
              controls: false,
              size: "mini",
              "step-strictly": "",
            },
            on: {
              change: function ($event) {
                return _vm.setColor("r", $event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        [
          _c("span", [_vm._v("G")]),
          _c("el-input-number", {
            attrs: {
              value: _vm.rgba[1],
              min: 0,
              max: 255,
              controls: false,
              size: "mini",
              "step-strictly": "",
            },
            on: {
              change: function ($event) {
                return _vm.setColor("g", $event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        [
          _c("span", [_vm._v("B")]),
          _c("el-input-number", {
            attrs: {
              value: _vm.rgba[2],
              min: 0,
              max: 255,
              controls: false,
              size: "mini",
              "step-strictly": "",
            },
            on: {
              change: function ($event) {
                return _vm.setColor("b", $event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        [
          _c("span", [_vm._v("A %")]),
          _c("el-input-number", {
            attrs: {
              value: _vm.rgba[3],
              min: 0,
              max: 255,
              controls: false,
              size: "mini",
              "step-strictly": "",
            },
            on: {
              change: function ($event) {
                return _vm.setColor("a", $event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "str" },
        [
          _c("span", [_vm._v("HEX")]),
          _c("el-input", {
            attrs: { size: "mini" },
            on: { change: _vm.setHex },
            model: {
              value: _vm.hex,
              callback: function ($$v) {
                _vm.hex = $$v
              },
              expression: "hex",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }