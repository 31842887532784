var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-ppt-pattern" },
    [
      _c(
        "div",
        { staticClass: "dsf-ppt-pattern-part1" },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "div",
                {
                  staticClass: "dsf-ppt-pattern-img",
                  class: { "has-img": _vm.src },
                  style: _vm.imgStyle,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "bt el-icon-upload",
                      on: {
                        click: function ($event) {
                          _vm.showDialog = true
                        },
                      },
                    },
                    [_vm._v(" 选择图片 ")]
                  ),
                ]
              ),
              _c(
                "el-popover",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.src,
                      expression: "src",
                    },
                  ],
                  staticClass: "dsf-ppt-pattern-preset",
                  attrs: {
                    "popper-class": "dsf-ppt-pattern-preset-popover",
                    placement: "bottom",
                    width: "240",
                    trigger: "click",
                    content:
                      "这是一段内容,这是一段内容,这是一段内容,这是一段内容。",
                  },
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "reference" }, slot: "reference" },
                    [_vm._v("预设")]
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { "label-width": "40px", size: "mini" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "尺寸" } },
                        [
                          _c(
                            "el-button-group",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "info", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setSize("fill")
                                    },
                                  },
                                },
                                [_vm._v("拉伸")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "info", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setSize("cover")
                                    },
                                  },
                                },
                                [_vm._v("铺满")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "info", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setSize("contain")
                                    },
                                  },
                                },
                                [_vm._v("适应")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "info", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setSize("auto")
                                    },
                                  },
                                },
                                [_vm._v("原大小")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-form-item", { attrs: { label: "位置" } }, [
                        _c(
                          "div",
                          { staticClass: "bt-9" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "info", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setPosition(1, 1)
                                  },
                                },
                              },
                              [_vm._v("左上")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "info", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setPosition(2, 1)
                                  },
                                },
                              },
                              [_vm._v("中上")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "info", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setPosition(3, 1)
                                  },
                                },
                              },
                              [_vm._v("右上")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "info", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setPosition(1, 2)
                                  },
                                },
                              },
                              [_vm._v("左中")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "info", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setPosition(2, 2)
                                  },
                                },
                              },
                              [_vm._v("居中")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "info", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setPosition(3, 2)
                                  },
                                },
                              },
                              [_vm._v("右中")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "info", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setPosition(1, 3)
                                  },
                                },
                              },
                              [_vm._v("左下")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "info", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setPosition(2, 3)
                                  },
                                },
                              },
                              [_vm._v("中下")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "info", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setPosition(3, 3)
                                  },
                                },
                              },
                              [_vm._v("右下")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("dsf-ppt-angle-select", {
            staticClass: "right",
            on: { change: _vm.updateValue },
            model: {
              value: _vm.rotation,
              callback: function ($$v) {
                _vm.rotation = $$v
              },
              expression: "rotation",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dsf-ppt-pattern-part2" },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "70px", size: "mini" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "重复选项" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.updateValue },
                      model: {
                        value: _vm.repeat,
                        callback: function ($$v) {
                          _vm.repeat = $$v
                        },
                        expression: "repeat",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "不重复", value: "no-repeat" },
                      }),
                      _c("el-option", {
                        attrs: { label: "重复", value: "repeat" },
                      }),
                      _c("el-option", {
                        attrs: { label: "水平重复", value: "repeat-x" },
                      }),
                      _c("el-option", {
                        attrs: { label: "垂直重复", value: "repeat-y" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "scale", attrs: { label: "缩放系数" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      min: 0,
                      step: 0.001,
                      precision: 3,
                      controls: false,
                      size: "mini",
                      "step-strictly": "",
                    },
                    on: { change: _vm.updateValue },
                    model: {
                      value: _vm.scaleX,
                      callback: function ($$v) {
                        _vm.scaleX = $$v
                      },
                      expression: "scaleX",
                    },
                  }),
                  _c("el-input-number", {
                    attrs: {
                      min: 0,
                      step: 0.001,
                      precision: 3,
                      controls: false,
                      size: "mini",
                      "step-strictly": "",
                    },
                    on: { change: _vm.updateValue },
                    model: {
                      value: _vm.scaleY,
                      callback: function ($$v) {
                        _vm.scaleY = $$v
                      },
                      expression: "scaleY",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "scale", attrs: { label: "旋转圆心" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      controls: false,
                      size: "mini",
                      "step-strictly": "",
                    },
                    on: { change: _vm.updateValue },
                    model: {
                      value: _vm.x,
                      callback: function ($$v) {
                        _vm.x = $$v
                      },
                      expression: "x",
                    },
                  }),
                  _c("el-input-number", {
                    attrs: {
                      controls: false,
                      size: "mini",
                      "step-strictly": "",
                    },
                    on: { change: _vm.updateValue },
                    model: {
                      value: _vm.y,
                      callback: function ($$v) {
                        _vm.y = $$v
                      },
                      expression: "y",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "scale", attrs: { label: "图片偏移" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      controls: false,
                      size: "mini",
                      "step-strictly": "",
                    },
                    on: { change: _vm.updateValue },
                    model: {
                      value: _vm.offsetX,
                      callback: function ($$v) {
                        _vm.offsetX = $$v
                      },
                      expression: "offsetX",
                    },
                  }),
                  _c("el-input-number", {
                    attrs: {
                      controls: false,
                      size: "mini",
                      "step-strictly": "",
                    },
                    on: { change: _vm.updateValue },
                    model: {
                      value: _vm.offsetY,
                      callback: function ($$v) {
                        _vm.offsetY = $$v
                      },
                      expression: "offsetY",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showDialog,
            title: "选择图片",
            width: "800px",
            "modal-append-to-body": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("dsf-ppt-img-library", {
            ref: "library",
            staticStyle: { height: "500px" },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "dsf-button",
                {
                  attrs: { size: "small", type: "plain" },
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "dsf-button",
                { attrs: { size: "small" }, on: { click: _vm.confirm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }