var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "dsf-ppt-metadata",
      class: { moving: _vm.isDown },
      style: _vm.elStyle,
    },
    [
      _c(
        "div",
        {
          staticClass: "title",
          attrs: { title: "可拖动窗口位置" },
          on: { mousedown: _vm.mousedown },
        },
        [_vm._v("插入字段")]
      ),
      _c(
        "dsf-virtual-scroll",
        { staticClass: "content", attrs: { height: "0" } },
        _vm._l(_vm.$ppt.metadataList, function (group) {
          return _c("div", { key: group.id, staticClass: "group" }, [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(group.title))]),
            _c(
              "div",
              { staticClass: "content" },
              _vm._l(group.items, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "item",
                    on: {
                      click: function ($event) {
                        return _vm.itemClick(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "ellipsis" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c("div", { staticClass: "ellipsis" }, [
                      _vm._v(_vm._s(item.key)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }