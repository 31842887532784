var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-ppt-attrs", class: { hide: _vm.hide } },
    [
      _c(
        "div",
        {
          staticClass: "title",
          on: {
            click: function ($event) {
              _vm.hide = !_vm.hide
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _c("i", {
            staticClass: "iconfont",
            class: [_vm.hide ? "icon-weibiaoti34" : "icon-xia1"],
          }),
        ]
      ),
      _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }