<template>
  <aside class="dsf-ppt-aside">
    <dsf-virtual-scroll>
      <div class="dsf-ppt-aside-list">
        <template v-for="(item, index) in imgList">
          <div
            :key="item.id"
            ref="item"
            v-context-menu="{params: {item}, data: treeMenu }"
            class="dsf-ppt-aside-list-item"
            :style="itemStyle"
            :active="active.id === item.id"
            @click="itemClick(index)">
            <img v-if="item.thumbnail" :src="item.thumbnail" alt=""/>
          </div>
        </template>
      </div>
      <div v-show="imgList.length < $ppt.propsMax" class="dsf-ppt-aside-addbt addbt iconfont icon-add1" title="新建页面" @click="addPage"></div>
    </dsf-virtual-scroll>
  </aside>
</template>

<script>
export default {
  name: "PptAside",
  inject: ['$ppt'],
  data() {
    return {
      treeMenu: [
        { icon: 'icon-fangkuai', name: '新建', hide: this.contextMenuHide, handler: this.addPage },
        { icon: 'icon-icon-add', name: '插入', hide: this.contextMenuHide, handler: this.insertPage },
        { icon: 'icon-fuzhi', name: '复制', hide: this.contextMenuHide, handler: this.copyPage },
        { icon: 'icon-shanchu', name: '删除此页', hide: this.contextMenuHide, handler: this.removePage },
        { icon: 'icon-delete', name: '删除所有', hide: this.contextMenuHide, handler: this.removeAllPage },
        { icon: 'icon-APIshuchu', name: '导出此页', handler: this.exportItem },
      ]
    }
  },
  computed: {
    imgList() {
      return this.$ppt.project?.imgList || [];
    },
    active() {
      return this.$ppt.activeImg || {};
    },
    itemStyle() {
      return {
        height: this.$ppt.thumbnailSize.height + 1 + 'px'
      }
    }
  },
  methods: {
    scrollTo(index) {
      this.$refs.item[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    },
    itemClick(index) {
      let item = this.$ppt.activeImg;
      if (this.$ppt.activeIndex === index) {
        return;
      }
      this.$ppt.activeIndex = index;
      if (this.$ppt.drawThumbnail) {
        this.$nextTick(() => {
          this.$ppt.drawThumbnail(item);
        });
      }
    },
    /********右键菜单*********/
    // 新建
    addPage() {
      this.$ppt.addPage();
    },
    // 插入
    insertPage({ params: { item }}) {
      let index = this.imgList.findIndex(it => it === item);
      this.$ppt.addPage(index);
    },
    // 拷贝此页
    copyPage({ params: { item }}) {
      let index = this.imgList.findIndex(it => it === item);
      this.$ppt.addPage(index, {
        id: dsf.uuid(8),
        layer: item.layer.clone()
      });
    },
    // 删除此页
    removePage({ params: { item }}) {
      let index = this.imgList.findIndex(it => it === item);
      this.$ppt.removePage(index);
    },
    // 删除所有
    removeAllPage() {
      this.$ppt.removeAllPage();
    },
    exportItem({ params: { item }}) {
      let index = this.imgList.findIndex(it => it === item);
      this.$ppt.exportItem(index);
    },
    contextMenuHide({menu: {name}}) {
      if (name == '删除此页' || name == '删除所有') {
        return this.imgList.length <= this.$ppt.propsMin;
      }
      return this.imgList.length == this.$ppt.propsMax;
    }
  }
}
</script>