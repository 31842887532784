import { render, staticRenderFns } from "./PptMetadata.vue?vue&type=template&id=3acbb1b0&"
import script from "./PptMetadata.vue?vue&type=script&lang=js&"
export * from "./PptMetadata.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("I:\\web-project\\dream\\tools\\dsf-product-ppt\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3acbb1b0')) {
      api.createRecord('3acbb1b0', component.options)
    } else {
      api.reload('3acbb1b0', component.options)
    }
    module.hot.accept("./PptMetadata.vue?vue&type=template&id=3acbb1b0&", function () {
      api.rerender('3acbb1b0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "pc/components/PptMetadata.vue"
export default component.exports