<template>
  <transition name="el-fade-in-linear">
    <div v-if="ready" class="ds-control dsf-ppt">
      <ppt-header multiple />
      <section class="dsf-ppt-section">
        <ppt-aside v-if="!isDesign" ref="aside" />
        <ppt-main v-if="!isDesign && activeImg !== null" ref="main" multiple />
      </section>
      <ppt-footer multiple />
      <ppt-config v-if="!isDesign" ref="config" />
      <ppt-metadata v-if="!isDesign && metadataList.length" ref="metadata"/>
    </div>
  </transition>
</template>

<script>
import mixins from './mixins';
import utils from '../utils/utils';
import { PptAside } from '../components';

export default dsf.component({
  name: "DsfPptsSetting",
  ctrlCaption: "图片组设计",
  mixins: [$mixins.control, mixins],
  props: {
    propsMin: {
      type: Number,
      default: 0
    },
    propsMax: {
      type: Number,
      default: 20
    }
  },
  components: { PptAside },
  data() {
    return {
      activeIndex: -1
    }
  },
  computed: {
    imgList() {
      return this.project?.imgList || [];
    },
    activeImg() {
      if (this.activeIndex < 0 || this.activeIndex >= this.imgList.length) return null;
      return this.imgList[this.activeIndex];
    },
    thumbnailSize() {
      let k = this.project.height / this.project.width;
      if (k < .5) k = .5;
      if (k > 1.5) k = 1.5;
      return {
        width: 121,
        height: Math.round(k * 121)
      }
    },
  },
  mounted() {
    if (this.isDesign) {
      this.ready = true;
    } else {
      utils.loadScript(() => {
        this.ready = true;
        this.$nextTick(() => {
          let imgList = [];
          for (let i = 0; i < this.propsMin; i++) {
            imgList.push({
              id: dsf.uuid(8),
              layer: null
            });
          }
          if (this.isDesignCtrl) {
            let {propsWidth, propsHeight, propsMimeType, propsQuality} = this;
            this.$set(this.project, 'width', propsWidth);
            this.$set(this.project, 'height', propsHeight);
            this.$set(this.project, 'mimeType', propsMimeType);
            this.$set(this.project, 'quality', propsQuality);
            this.$set(this.project, 'imgList', imgList);
          } else {
            this.$refs.config.open(() => {
              this.$set(this.project, 'imgList', imgList);
            });
          }
          if (this.propsMin) {
            this.activeIndex = 0;
          }
          this.$nextTick(() => this.$dispatch('ready'));
        });
      });
    }
  },
  methods: {
    // 重设尺寸
    async updateRect() {
      let w = this.thumbnailSize.width;
      let {width, height} = this.project;
      this.konva.setAttrs({width, height});
      for (let i in this.imgList) {
        let item = this.imgList[i];
        item.layer.setAttrs({width, height});
        item.thumbnail = await utils.layerToBase64(item.layer, width, height, {
          pixelRatio: w / width,
          mimeType: 'image/jpeg',
          quality: .7
        }, true);
      }
      if (this.activeImg) {
        this.konva.add(this.activeImg.layer);
        this.$refs.main.resize();
      }
    },
    /********按钮*********/
    // 保存
    async save() {
      let {uploadHistoryList, project} = this;
      let {width, height, mimeType, quality, imgList} = project;
      project = {width, height, mimeType, quality, imgList: imgList.map(({layer}) => layer ? layer.toObject() : null)};
      let json = {project, uploadHistoryList};
      let files = imgList.length ? await utils.exportByData(project) : [];
      this.$dispatch('save', {files, json});
    },
    // 导出
    async export() {
      if (!this.imgList.length) {
        dsf.layer.message('没有图片可以导出', false);
        return;
      }
      let {width, height, mimeType, quality} = this.project;
      let zip = new window.JSZip();
      let project = {width, height, mimeType, quality, imgList: []};
      let suffix = mimeType === 'image/png' ? '.png' : '.jpeg';
      for (let i in this.imgList) {
        let {layer} = this.imgList[i];
        if (i == this.activeIndex) {
          layer = layer.clone();
        }
        project.imgList.push(layer.toObject());
        let base64 = await utils.layerToBase64(layer, width, height, {
          mimeType,
          quality
        }, true);
        zip.file(`${+i + 1}${suffix}`, base64.split('base64,')[1], {base64: true});
      }
      let uploadHistoryList = _.map(this.uploadHistoryList, 'relativePath');
      zip.file('config.json', JSON.stringify({project, uploadHistoryList}), {comment: '项目配置文件'});
      zip.generateAsync({type: "blob"}).then((blob) => {
        window.saveAs(blob, "ppt.zip");
      });
    },
    // 本地导入
    async importLocal(data) {
      if (!data) {
        data = await dsf.file.readJSON()
      }
      let w = this.thumbnailSize.width;
      let {width: ow, height: oh, mimeType: om, quality: oq} = this.project;
      let {project: {width, height, mimeType, quality, imgList}, uploadHistoryList = []} = data;
      // 不允许修改配置
      if (this.propsDisabledConfig) {
        width = ow;
        height = oh;
        mimeType = om;
        quality = oq;
      }
      this.konva?.setAttrs({
        width,
        height
      });
      if (imgList.length > this.propsMax) {
        imgList.length = this.propsMax;
      } else if (imgList.length < this.propsMin){
        for (let i = 0, l = this.propsMin - imgList.length; i < l; i++) {
          imgList.push(null)
        }
      }
      let imgList2 = [];
      for (let i in imgList) {
        let it = imgList[i];
        if (!it || !Object.values(it).length) {
          imgList2.push({
            id: dsf.uuid(8),
            layer: null
          });
          continue;
        }
        let layer = Konva.Node.create(it, 'layer');
        layer.attrs.width = width;
        layer.attrs.height = height;
        let bgDom = layer.findOne('#bg');
        bgDom.attrs.width = width;
        bgDom.attrs.height = height;
        let thumbnail = await utils.layerToBase64(layer, width, height, {
          pixelRatio: w / width,
          mimeType: 'image/jpeg',
          quality: .7
        }, true);
        imgList2.push({
          id: dsf.uuid(8),
          layer,
          thumbnail
        });
      }
      this.project = {width, height, mimeType, quality, imgList: imgList2};
      this.uploadHistoryList = _.map(uploadHistoryList, relativePath => {
        return {
          id: dsf.uuid(8),
          relativePath
        }
      });
      if (imgList.length) {
        this.activeIndex = 0;
      }
    },
    // 远端导入
    // importServe() {
    //
    // },
    // 新建页面
    addPage(index = this.imgList.length, obj) {
      this.drawThumbnail();
      if (!obj) {
        obj = {
          id: dsf.uuid(8),
          layer: null
        };
      }
      this.imgList.splice(index, 0, obj);
      this.activeIndex = index;
      this.$nextTick(() => {
        this.$refs.aside.scrollTo(index);
      })
    },
    // 删除此页
    removePage(index) {
      dsf.layer.confirm("确定要删除此页吗？").then(() => {
        let newIndex = this.activeIndex;
        if (index === undefined) {
          index = this.activeIndex
        }
        this.imgList.splice(index, 1);
        if (index <= this.activeIndex) {
          newIndex--;
        }
        if (this.activeIndex >= this.imgList.length) {
          newIndex = this.imgList.length - 1;
        }
        if (newIndex < 0 && this.imgList.length) {
          newIndex = 0;
        }
        this.activeIndex = newIndex;
      }).catch(e => {
        if (e !== 'cancel') {
          dsf.error(e);
        }
      });
    },
    // 删除所有
    removeAllPage() {
      let imgList = [];
      for (let i = 0; i < this.propsMin; i++) {
        imgList.push({
          id: dsf.uuid(8),
          layer: null
        });
      }
      dsf.layer.confirm("确定要全部删除吗？").then(() => {
        this.$set(this.project, 'imgList', imgList);
        this.activeIndex = this.propsMin ? 1 : -1;
      }).catch(e => {
        if (e !== 'cancel') {
          dsf.error(e);
        }
      });
    },
    // 导出单页
    async exportItem(index) {
      let item = this.imgList[index];
      let {width, height, mimeType, quality} = this.project;
      let base64 = await utils.layerToBase64(item.layer, width, height, {mimeType, quality}, true);
      let blob = utils.base64toBlob(base64);
      let suffix = mimeType === 'image/png' ? '.png' : '.jpeg';
      window.saveAs(blob, index + 1 + suffix);
      if (index === this.activeIndex) {
        this.konva.add(item.layer);
      }
    },
    /**********监听***********/
    // 预览图绘制
    async drawThumbnail(item = this.activeImg) {
      if (!item?.layer) return;
      let w = this.thumbnailSize.width;
      let {width, height} = this.project;
      this.$set(item, 'thumbnail', await utils.layerToBase64(item.layer, width, height,{
        pixelRatio: w / this.project.width,
        mimeType: 'image/jpeg',
        quality: .7
      }, true));
      if (item === this.activeImg) {
        this.konva.add(item.layer);
      }
    }
  }
});
</script>