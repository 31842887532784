var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-ppt-tool-section" },
    [
      _c("div", { staticClass: "dsf-ppt-tool-title" }, [_vm._v("背景属性")]),
      _vm.bgDom ? _c("dsf-ppt-fill", { attrs: { node: _vm.bgDom } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }