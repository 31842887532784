<template>
  <div class="dsf-ppt-tool-section">
    <div class="dsf-ppt-tool-title">图片属性</div>
    <dsf-virtual-scroll height="0">
      <base-attrs-item ref="base" />
      <fill-attrs-item ref="fill" />
      <shadow-attrs-item ref="shadow" />
      <stroke-attrs-item ref="stroke" />
    </dsf-virtual-scroll>
  </div>
</template>

<script>
import mixins from './mixins';

export default {
  name: "PptImageAttr",
  mixins: [mixins]
}
</script>