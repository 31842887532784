<template>
  <main class="dsf-ppt-main">
    <ppt-shape v-if="!multiple" />
    <div class="dsf-ppt-main-canvas">
      <transition name="el-fade-in-linear">
        <span v-if="showScaleTips" class="scale-tips">{{ scale }}%</span>
      </transition>
      <template v-if="$ppt.showMousePoint || ctrlKeyDown">
        <div class="mouse-point __x" :p="Math.floor((mousePoint.x - 14 - rulerXOrigin) * 100 / scale)" :style="{left: mousePoint.x + 'px'}"></div>
        <div class="mouse-point __y" :p="Math.floor((mousePoint.y - 14 - rulerYOrigin) * 100 / scale)" :style="{top: mousePoint.y + 'px'}"></div>
      </template>
      <template v-if="$ppt.showRuler || ctrlKeyDown">
        <div class="canvas-point __x" :style="{width: realWidth + 1 + 'px' ,left: rulerXOrigin + 14 + 'px'}"></div>
        <div class="canvas-point __y" :style="{height: realHeight + 1 + 'px' ,top: rulerYOrigin + 14 + 'px'}"></div>
        <div class="ruler __x" :style="rulerXStyle">
          <span class="origin" :style="rulerXOriginStyle">0</span>
        </div>
        <div class="ruler __y" :style="rulerYStyle">
          <span class="origin" :style="rulerYOriginStyle">0</span>
        </div>
      </template>
      <div ref="box" class="canvas-box" @mousemove="boxMousemove" @wheel.stop.prevent="onWheel">
        <div ref="canvas" v-context-menu="{data: contextMenuList}" class="canvas" :style="canvasStyle"></div>
      </div>
      <div ref="xBar" class="scroll-bar __x" :class="{isDown: isDownX}">
        <div ref="xThumb" :style="scrollBarXStyle" @mousedown="xBarMousedown"></div>
      </div>
      <div ref="yBar" class="scroll-bar __y" :class="{isDown: isDownY}">
        <div ref="yThumb" :style="scrollBarYStyle" @mousedown="yBarMousedown"></div>
      </div>
    </div>
    <ppt-shape v-if="multiple" class="multiple" />
    <ppt-tool />
  </main>
</template>

<script>
import PptTool from './PptTool';
import PptShape from "./PptShape";

export default {
  name: "PptMain",
  inject: ['$ppt'],
  provide() {
    return {
      $pptMain: this
    }
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PptTool,
    PptShape
  },
  data() {
    return {
      ctrlKeyDown: false,
      activeShape: null,
      oldBoxW: 0,
      oldBoxH: 0,
      top: 0,
      left: 0,
      scale: 100,
      showScrollXBar: false,
      showScrollYBar: false,
      scrollBarXStyle: {},
      scrollBarYStyle: {},
      isDownX: false,
      isDownY: false,
      showScaleTips: false,
      mousePoint: {x: 0, y: 0},
      contextMenuList: [
        { icon: 'icon-fuzhi', name: '复制图层', hide: this.contextMenuHide, handler: this.copyHandler },
        { icon: 'icon-gongdanqueren', name: '粘贴图层', hide: this.contextMenuHide, handler: this.pasteHandler },
        { icon: 'icon-delete', name: '删除图层', hide: this.contextMenuHide, handler: this.removeHandler },
        { icon: 'icon-delete', name: '清空画布', handler: this.removeAllHandler },
        { icon: 'icon-APIshuchu', name: '导出此页', handler: this.exportItem }
      ]
    }
  },
  computed: {
    project() {
      return this.$ppt.project;
    },
    activeImg() {
      return this.$ppt.activeImg;
    },
    canvasStyle() {
      let width = this.project.width;
      let height = this.project.height;
      return {
        top: Math.floor(this.top) + 'px',
        left: Math.floor(this.left) + 'px',
        width: width + 'px',
        height: height + 'px',
        transform: `translate(-50%, -50%) scale(${this.scale / 100})`
      };
    },
    realWidth() {
      return this.project.width * this.scale / 100;
    },
    rulerXOrigin() {
      return this.left - this.realWidth / 2 - 15;
    },
    rulerXStyle() {
      let s = this.scale;
      let x = Math.floor(this.rulerXOrigin - 1);
      return {
        'background-position-x': `${x}px, ${x}px`,
        'background-size': `${s}px 5px, ${s}px 10px`
      }
    },
    rulerXOriginStyle() {
      return {
        left: Math.floor(this.rulerXOrigin) + 'px'
      }
    },
    realHeight() {
      return this.project.height * this.scale / 100;
    },
    rulerYOrigin() {
      return this.top - this.realHeight / 2 - 15;
    },
    rulerYStyle() {
      let s = this.scale;
      let x = Math.floor(this.rulerYOrigin - 1);
      return {
        'background-position-y': `${x}px, ${x}px`,
        'background-size': `5px ${s}px, 10px ${s}px`
      }
    },
    rulerYOriginStyle() {
      return {
        top: Math.floor(this.rulerYOrigin) + 'px'
      }
    }
  },
  watch: {
    scale() {
      this.showScaleTips = true;
      clearTimeout(this.__showScaleTipsTimeout);
      this.__showScaleTipsTimeout = setTimeout(() => {
        this.showScaleTips = false;
      }, 1000);
    }
  },
  mounted() {
    let {width, height} = this.project;
    this.$ppt.konva = new window.Konva.Stage({
      container: this.$refs.canvas,
      width,
      height
    });
    this._watch = this.$watch('activeImg.id', () => {
      this.init();
    }, {
      immediate: true
    });
    window.addEventListener('resize', this.resize);
    window.addEventListener('mousemove', this.mousemove);
    window.addEventListener('mouseup', this.mouseup);
    window.addEventListener('keydown', this.keydown);
    window.addEventListener('keyup', this.keyup);
    this.resize();
    this.boxRect = this.$refs.box.getBoundingClientRect();
  },
  beforeDestroy() {
    this._watch();
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('mousemove', this.mousemove);
    window.removeEventListener('mouseup', this.mouseup);
    window.removeEventListener('keydown', this.keydown);
    window.removeEventListener('keyup', this.keyup);
  },
  methods: {
    init() {
      let konva = this.$ppt.konva;
      let layer = this.activeImg.layer;
      let {width, height} = this.project;
      if (!layer) {
        layer = new Konva.Layer();
        layer.add(new Konva.Rect({
          id : 'bg',
          x: 0,
          y: 0,
          width,
          height,
          fill: '#fff'
        }));
        this.$set(this.activeImg, 'layer', layer);
      } else if (konva.find('Transformer').length) {
        konva.find('Transformer').forEach(t => t.destroy());
      }
      konva.off('click').off('focus').off('blur');
      konva.on('click', (e) => {
        // remove old transformers
        if (konva.find('Transformer').length) {
          konva.find('Transformer').forEach(t => t.destroy());
        }
        // do nothing if clicked NOT on our rectangles
        if (!e.target || !e.target.hasName('component')) {
          this.activeShape = null;
          layer.draw();
          return;
        }
        // create new transformer
        var tr = new Konva.Transformer();
        layer.add(tr);
        tr.attachTo(e.target);
        layer.draw();
        this.activeShape = e.target;
      }).on('focus', $editor => {
        this.$ppt.editor = $editor;
      }).on('blur', () => {
        this.$ppt.editor = null;
      })
      konva.removeChildren();
      konva.add(layer);
      this.activeShape = null;
    },
    resize() {
      let {offsetWidth, offsetHeight} = this.$refs.box;
      let {width, height} = this.project;
      let {scale, top, left, oldBoxW, oldBoxH} = this;
      scale /= 100;
      width = width * scale;
      height = height * scale;
      let Top = offsetHeight / 2;
      let Left = offsetWidth / 2;
      if (oldBoxW || oldBoxH) {
        if (width > offsetWidth) {
          Left = (offsetWidth - oldBoxW) / 2 + left;
        }
        if (height > offsetHeight) {
          Top = (offsetHeight - oldBoxH) / 2 + top;
        }
      }
      this.oldBoxW = offsetWidth;
      this.oldBoxH = offsetHeight;
      this.alignPosition(Top, Left, width, height, offsetWidth, offsetHeight);
    },
    setScale(scale) {
      let {offsetWidth, offsetHeight} = this.$refs.box;
      let {width, height} = this.project;
      let {top, left} = this;
      this.scale = scale;
      width = width * scale / 100;
      height = height * scale / 100;
      this.alignPosition(top, left, width, height, offsetWidth, offsetHeight);
    },
    boxMousemove(e) {
      let {top, left} = this.boxRect;
      this.mousePoint = {
        x: e.pageX - left,
        y: e.pageY - top,
      }
    },
    onWheel(e) {
      let deltaX = e.deltaX, deltaY = e.deltaY;
      if (e.shiftKey && deltaY) {
        deltaX = deltaY;
        deltaY = 0;
      }
      // 如果是火狐浏览器，并且不是由鼠标滚轮触发
      // 火狐浏览器e.deltaMode：0触摸板，e.deltaMode：1鼠标
      // 其他浏览器e.deltaMode始终为0
      // 火狐浏览器鼠标delta值3或-3
      // 其他浏览器鼠标delta值100或-100
      if (dsf.client.type === "FF" && e.deltaMode === 1) {
        if (deltaX) deltaX = deltaX < 0 ? -10 : 10;
        if (deltaY) deltaY = deltaY < 0 ? -10 : 10;
      } else {
        if (Math.abs(deltaX) < 100) {
          deltaX /= 2;
        } else {
          deltaX /= 10;
        }
        if (Math.abs(deltaY) < 100) {
          deltaY /= 2;
        } else {
          deltaY /= 10;
        }
      }
      this._mouseWheel(deltaX, deltaY, e.x, e.y, e.altKey);
    },
    _mouseWheel(deltaX, deltaY, x, y, alt) {
      let {offsetWidth, offsetHeight} = this.$refs.box;
      let {width, height} = this.project;
      let {scale, top, left} = this;
      width = width * scale / 100;
      height = height * scale / 100;
      let Top = top, Left = left;
      // 缩放
      if (alt) {
        let offScale = (deltaX + deltaY) > 0 ? -1 : 1;
        scale += offScale;
        if (scale < 30) return;
        offScale = scale / (scale - offScale);
        let boxRect = this.boxRect;
        let mousePointX = x - boxRect.left;
        let mousePointY = y - boxRect.top;
        Left = (Left - mousePointX) * offScale + mousePointX;
        Top = (Top - mousePointY) * offScale + mousePointY;
        this.scale = scale;
      } else {
        // 水平移动
        if (deltaX) {
          Left -= deltaX;
        }
        // 垂直移动
        if (deltaY) {
          Top -= deltaY;
        }
      }
      this.alignPosition(Top, Left, width, height, offsetWidth, offsetHeight);
    },
    // 校准坐标
    alignPosition(top, left, width, height, offsetWidth, offsetHeight) {
      let halfWidth = width / 2;
      let halfHeight = height / 2;
      let x = left - halfWidth, x2 = left + halfWidth;
      let y = top - halfHeight, y2 = top + halfHeight;
      let xBarW = this.$refs.xBar.offsetWidth;
      let yBarH = this.$refs.yBar.offsetHeight;
      let showScrollXBar = width > offsetWidth - 30;
      let showScrollYBar = height > offsetHeight - 30;
      let scrollBarXStyle = {};
      let scrollBarYStyle = {};
      if (showScrollXBar) {
        if (x > 15) {
          left = 15 + halfWidth;
        } else if (x2 < offsetWidth - 15) {
          left = offsetWidth - 15 - halfWidth;
        }
        let offsetScroll = width + 15 - offsetWidth;
        let scrollWidth = offsetScroll * 2 + offsetWidth;
        scrollBarXStyle.width = xBarW * width / scrollWidth + 'px';
        scrollBarXStyle.left = xBarW * (left + offsetScroll) / scrollWidth + 'px';
      } else {
        if (x < 15) {
          left = 15 + halfWidth;
        } else if (x2 > offsetWidth - 15) {
          left = offsetWidth - 15 - halfWidth;
        }
        scrollBarXStyle.width = xBarW * width / (offsetWidth - 30) + 'px';
        scrollBarXStyle.left = xBarW * (left - 15) / (offsetWidth - 30) + 'px';
      }
      if (showScrollYBar) {
        if (y > 15) {
          top = 15 + halfHeight;
        }
        if (y2 < offsetHeight - 15) {
          top = offsetHeight - 15 - halfHeight;
        }
        let offsetScroll = height + 15 - offsetHeight;
        let scrollHeight = offsetScroll * 2 + offsetHeight;
        scrollBarYStyle.height = yBarH * height / scrollHeight + 'px';
        scrollBarYStyle.top = yBarH * (top + offsetScroll) / scrollHeight + 'px';
      } else {
        if (y < 15) {
          top = 15 + halfHeight;
        } else if (y2 > offsetHeight - 15) {
          top = offsetHeight - 15 - halfHeight;
        }
        let scrollHeight = offsetHeight - 30;
        scrollBarYStyle.height = yBarH * height / scrollHeight + 'px';
        scrollBarYStyle.top = yBarH * (top - 15) / scrollHeight + 'px';
      }
      this.top = top;
      this.left = left;
      this.showScrollXBar = showScrollXBar;
      this.showScrollYBar = showScrollYBar;
      this.scrollBarXStyle = scrollBarXStyle;
      this.scrollBarYStyle = scrollBarYStyle;
    },
    xBarMousedown(e) {
      this.isDownX = true;
      let {screenX} = e;
      let {offsetLeft, offsetWidth} = e.target;
      this._offsetX = this._SoffsetX = offsetLeft - offsetWidth / 2;
      this._screenX = screenX;
    },
    yBarMousedown(e) {
      this.isDownY = true;
      let {screenY} = e;
      let {offsetTop, offsetHeight} = e.target;
      this._offsetY = this._SoffsetY = offsetTop - offsetHeight / 2;
      this._screenY = screenY;
    },
    mousemove(e) {
      let {screenX, screenY} = e;
      if (this.isDownX) {
        this._offsetX = this._SoffsetX + screenX - this._screenX;
        this.setScrollLeft();
      }
      if (this.isDownY) {
        this._offsetY = this._SoffsetY + screenY - this._screenY;
        this.setScrollTop();
      }
    },
    mouseup(e) {
      let {screenX, screenY} = e;
      if (this.isDownX) {
        this._offsetX = this._SoffsetX + screenX - this._screenX;
        this.isDownX = false;
        this.setScrollLeft();
      }
      if (this.isDownY) {
        this._offsetY = this._SoffsetY + screenY - this._screenY;
        this.isDownY = false;
        this.setScrollTop();
      }
    },
    setScrollTop() {
      let {offsetWidth, offsetHeight} = this.$refs.box;
      let {width, height} = this.project;
      let {top, left, scale} = this;
      let {offsetHeight: boxL} = this.$refs.yBar;
      let {offsetHeight: thumbL} = this.$refs.yThumb;
      let realWidth = width * scale / 100;
      let realHeight = height * scale / 100;
      let offY = this._offsetY;
      if (offY < 0) {
        offY = 0;
      } else if (offY + thumbL > boxL) {
        offY = boxL - thumbL;
      }
      let scrollHeight;
      if (this.showScrollYBar) {
        let offsetScroll = realHeight + 15 - offsetHeight;
        scrollHeight = offsetScroll * 2 + offsetHeight - realHeight;
        top = Math.floor(scrollHeight * offY / (boxL - thumbL) + realHeight / 2 - offsetScroll);
      } else {
        scrollHeight = offsetHeight - 30 - realHeight;
        top = Math.floor(scrollHeight * offY / (boxL - thumbL) + realHeight / 2 + 15);
      }
      this.alignPosition(top, left, realWidth, realHeight, offsetWidth, offsetHeight);
    },
    setScrollLeft() {
      let {offsetWidth, offsetHeight} = this.$refs.box;
      let {width, height} = this.project;
      let {top, left, scale} = this;
      let {offsetWidth: boxL} = this.$refs.xBar;
      let {offsetWidth: thumbL} = this.$refs.xThumb;
      let realWidth = width * scale / 100;
      let realHeight = height * scale / 100;
      let offX = this._offsetX;
      if (offX < 0) {
        offX = 0;
      } else if (offX + thumbL > boxL) {
        offX = boxL - thumbL;
      }
      let scrollWidth;
      if (this.showScrollXBar) {
        let offsetScroll = realWidth + 15 - offsetWidth;
        scrollWidth = offsetScroll * 2 + offsetWidth - realWidth;
        left = Math.floor(scrollWidth * offX / (boxL - thumbL) + realWidth / 2 - offsetScroll);
      } else {
        scrollWidth = offsetWidth - 30 - realWidth;
        left = Math.floor(scrollWidth * offX / (boxL - thumbL) + realWidth / 2 + 15);
      }
      this.alignPosition(top, left, realWidth, realHeight, offsetWidth, offsetHeight);
    },
    keydown(e) {
      // shift
      if (e.keyCode === 16) {
        e.preventDefault();
        this.ctrlKeyDown = true;
      }
    },
    keyup(e) {
      if (e.keyCode === 16) {
        e.preventDefault();
        this.ctrlKeyDown = false;
      }
    },
    /*******右键菜单*******/
    contextMenuHide({menu: {name}}) {
      if (!this.$ppt.copyNode && name == '粘贴图层') {
        return true;
      }
      if (!this.activeShape) {
        return name == '复制图层' || name == '删除图层';
      }
      return false;
    },
    // 复制
    copyHandler() {
      if (this.activeShape) {
        this.$ppt.copyNode = this.activeShape.clone();
        dsf.layer.message('已复制到粘贴板');
      }
    },
    // 粘贴
    pasteHandler() {
      let copyNode = this.$ppt.copyNode;
      if (copyNode) {
        let node = copyNode.clone();
        this.activeImg.layer.add(node);
      }
    },
    // 删除
    removeHandler() {
      let node = this.activeShape;
      this.$ppt.konva.fire('click', {
        target: null
      });
      setTimeout(() => {
        node.destroy();
      }, 100);
    },
    // 清空
    removeAllHandler() {
      let layer = this.activeImg.layer;
      let bgDom = layer.findOne('#bg').clone();
      layer.destroyChildren();
      layer.add(bgDom);
    },
    // 导出此页
    exportItem() {
      let index = this.$ppt.activeIndex;
      this.$ppt.exportItem(index);
    }
  }
}
</script>