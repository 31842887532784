import Quill from 'quill';

// 自定义字体
let FontAttributor = Quill.import('attributors/style/font');
FontAttributor.whitelist = [
  '宋体', '黑体', '微软雅黑', '微软正黑体', '新宋体', '新细明体',
  '细明体', '标楷体', '仿宋', '楷体',
  'Arial', 'PmingLiu', 'Impact', 'Georgia', 'Tahoma', 'Book Antiqua',
  'Century Gothic', 'Courier New', 'Times New Roman', 'Verdana'
];
Quill.register(FontAttributor);
// 自定义字体
let sizeAttributor = Quill.import('attributors/style/size');
sizeAttributor.whitelist = Array(46).fill('').map((v, i) => 10 + i * 2 + 'px');
Quill.register(sizeAttributor);

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': sizeAttributor.whitelist }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': FontAttributor.whitelist }],
  [{ 'align': [] }],

  ['clean'],                                         // remove formatting button
];

export default class PptQuillEditor{
  #top = 0;
  #left = 0;
  #width = 0;
  #height = 0;
  #scaleX = 1;
  #scaleY = 1;
  #rotation = 0;

  set top(val) {this.#top = val;this.setStyle();}
  get top() { return this.#top; }
  set left(val) {this.#left = val;this.setStyle();}
  get left() { return this.#left; }
  set width(val) {this.#width = val;this.setStyle();}
  get width() { return this.#width; }
  set height(val) {this.#height = val;this.setStyle();}
  get height() { return this.#height; }
  set scaleX(val) {this.#scaleX = val;this.setStyle();}
  get scaleX() { return this.#scaleX; }
  set scaleY(val) {this.#scaleY = val;this.setStyle();}
  get scaleY() { return this.#scaleY; }
  set rotation(val) {this.#rotation = val;this.setStyle();}
  get rotation() { return this.#rotation; }

  constructor(node) {
    this.node = node;
    this.template();
    this.$el.innerHTML = node.html();
    let scaleX = node.scaleX() || 1;
    if (scaleX) {
      this.scaleX = scaleX;
    }
    let scaleY = node.scaleY() || 1;
    if (scaleY) {
      this.scaleY = scaleY;
    }
    this.rotation = node.rotation();
    this.quill = new Quill(this.$el, {
      theme: 'bubble',
      modules: {
        toolbar: toolbarOptions
      }
    });
    this.firstChild = this.$el.firstChild;
    this.quill.focus();
    this.quill.on('editor-change', this.editorChange.bind(this));
    this.firstChild.style.transform = `rotateZ(${this.rotation}deg) scaleX(${this.scaleX}) scaleY(${this.scaleY})`;
    this.firstChild.style.width = node.width() + 'px';
    this.#updateSize();

    node.fire('focus', this);
    node.getStage().fire('focus', this);

    setTimeout(() => {
      this.destroy = this.#destroy.bind(this);
      window.addEventListener('click', this.destroy);
    });
  }

  template() {
    let $el = document.createElement('div');
    $el.classList.add('dsf-ppt-konva-quill');
    $el.onclick = e => {
      e.preventDefault();
      e.stopPropagation();
    }
    this.$el = $el;
    this.$box = this.node.getStage().content.parentElement;
    this.$box.appendChild($el);
  }

  setStyle() {
    this.$el.style.top = this.top + 'px';
    this.$el.style.left = this.left + 'px';
    this.$el.style.width = this.width * this.scaleX + 'px';
    this.$el.style.height = this.height * this.scaleY + 'px';
  }

  // 插入值
  insertText(text) {
    this.quill.focus();
    let {index, length} = this.quill.getSelection();
    this.quill.deleteText(index, length);
    this.quill.insertText(index, text);
    index += text.length;
    this.quill.setSelection(index);
  }

  #updateSize() {
    // 计算此时的坐标
    let node = this.node;
    let textPosition = node.absolutePosition();
    this.top = textPosition.y;
    this.left = textPosition.x;
  }

  // eslint-disable-next-line no-dupe-class-members
  async #destroy(e) {
    if ($(e.target).closest('.dsf-ppt-metadata').get(0)) {
      return
    }
    this.node.fire('blur', this);
    this.node.getStage().fire('blur', this);
    let text = this.firstChild.innerHTML;
    window.removeEventListener('click', this.destroy);
    window.removeEventListener('resize', this.updateSize);
    await this.node.html(text);
    this.node.show();
    this.$box.removeChild(this.$el);
    for (let k in this) {
      this[k] = null;
    }
  }

  editorChange() {
    this.width = parseInt(this.firstChild.style.width) * this.scaleX;
    this.height = this.firstChild.scrollHeight * this.scaleX;
  }
}