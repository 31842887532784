<template>
  <div class="dsf-ppt-img-library">
    <el-upload
      class="upload-demo"
      :action="action"
      :show-file-list="false"
      :before-upload="beforeUpload"
      accept=".jpg,.png,.jpeg,.gif"
      append-to-body
      drag
      multiple
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
    </el-upload>
    <div class="title">上传历史</div>
    <div class="history">
      <dsf-virtual-scroll width="100%">
        <div v-if="!history.length" class="no-data">暂无上传历史</div>
        <template v-for="(item, index) in history">
          <div :key="index" class="history-item">
            <el-image :src="item | imgFilter" :preview-src-list="srcList" fit="cover" lazy />
            <div v-if="item.status" class="status" :title="item.status === 'fail' ? '上传失败' : ''">
              <i v-show="item.status === 'wait'" class="el-icon-loading"></i>
              <i v-show="item.status === 'fail'" class="el-icon-error"></i>
            </div>
            <el-checkbox v-else-if="multiple" :value="selectIds.indexOf(item.id) > -1" @change="itemClickMultiple(item)"></el-checkbox>
            <el-checkbox v-else :value="selectItem && selectItem.id === item.id" @change="itemClick(item)"></el-checkbox>
          </div>
        </template>
      </dsf-virtual-scroll>
    </div>
  </div>
</template>

<script>
import utils from '../utils';

export default {
  name: "DsfPptImgLibrary",
  inject: ['$ppt'],
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectIds: [],
      selectItem: null,
      action: dsf.url.getWebPath('file/upload')
    }
  },
  computed: {
    history() {
      return this.$ppt.uploadHistoryList;
    },
    srcList() {
      return this.history.map(v => {
        if (v.uuid) {
          return v.relativePath;
        }
        return dsf.url.getWebPath(v.relativePath);
      })
    }
  },
  filters: {
    imgFilter(obj) {
      if (obj.uuid) {
        return obj.relativePath;
      }
      return dsf.url.getWebPath(obj.relativePath);
    }
  },
  created() {
    this.selectList = [];
  },
  methods: {
    beforeUpload(file) {
      this.$ppt.uploadHistoryList.push({
        uuid: dsf.uuid(8),
        relativePath: URL.createObjectURL(file),
        status: 'wait'
      });
      this.uploadFile(file, this.history.length - 1);
      return false;
    },
    onSuccess(data, index) {
      this.$ppt.$set(this.$ppt.uploadHistoryList, index, data);
    },
    onError(index) {
      this.$ppt.$set(this.$ppt.uploadHistoryList[index], 'status', 'fail');
    },
    uploadFile(file, index) {
      utils.uploadFile.call(this, file).then(data => {
        this.onSuccess(data, index);
      }).catch(() => {
        this.onError(index);
      });
    },
    itemClickMultiple(item) {
      if (!item.status) {
        let index = this.selectIds.indexOf(item.id);
        if (index > -1) {
          this.selectIds.splice(index, 1);
          this.selectList.splice(index, 1);
        } else {
          this.selectIds.push(item.id);
          this.selectList.push(item);
        }
      }
    },
    itemClick(item) {
      if (!item.status) {
        this.selectItem = item;
      }
    },
    yes() {
      if (this.multiple) {
        return this.selectList;
      }
      return this.selectItem;
    }
  }
}
</script>