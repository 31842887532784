var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ppt-attr-panel", { attrs: { title: "阴影" } }, [
    _c(
      "div",
      { staticClass: "dsf-ppt-attrs-row" },
      [
        _c("dsf-ppt-color-select", {
          attrs: { value: _vm.shadowColor },
          on: {
            input: function ($event) {
              return _vm.$attr.setAttrsByStr(_vm.vm, "shadowColor", $event)
            },
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("偏移")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c("span", [_vm._v("X")]),
          _c("el-input", {
            attrs: { value: _vm.shadowOffsetX, size: "mini" },
            on: {
              input: function ($event) {
                return _vm.$attr.setAttrs(_vm.vm, "shadowOffsetX", $event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c("span", [_vm._v("Y")]),
          _c("el-input", {
            attrs: { value: _vm.shadowOffsetY, size: "mini" },
            on: {
              input: function ($event) {
                return _vm.$attr.setAttrs(_vm.vm, "shadowOffsetY", $event)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("扩散")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c("el-input", {
            attrs: { value: _vm.shadowBlur, size: "mini" },
            on: {
              input: function ($event) {
                return _vm.$attr.setAttrs(_vm.vm, "shadowBlur", $event)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("透明")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c("el-slider", {
            attrs: {
              value: _vm.shadowOpacity,
              min: 0,
              max: 1,
              step: 0.000001,
              size: "mini",
            },
            on: {
              input: function ($event) {
                return _vm.$attr.setAttrs(_vm.vm, "shadowOpacity", $event)
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }