<template>
  <ppt-attr-panel title="基本属性">
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">坐标</div>
      <label class="dsf-ppt-attrs-row__column">
        <span>X</span>
        <el-input :value="x" :controls="false" size="mini" @input="$attr.setAttrs(vm, 'x', $event)" />
      </label>
      <label class="dsf-ppt-attrs-row__column">
        <span>Y</span>
        <el-input :value="y" :controls="false" size="mini" @input="$attr.setAttrs(vm, 'y', $event)" />
      </label>
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">尺寸</div>
      <label class="dsf-ppt-attrs-row__column">
        <span>W</span>
        <el-input :value="width" size="mini" @input="$attr.setAttrs(vm, 'width', $event)" @change="$emit('change')" />
      </label>
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">缩放</div>
      <label class="dsf-ppt-attrs-row__column">
        <span>X</span>
        <el-input :value="scaleX" size="mini" @input="$attr.setAttrs(vm, 'scaleX', $event)" @change="$emit('change')" />
      </label>
      <label class="dsf-ppt-attrs-row__column">
        <span>Y</span>
        <el-input :value="scaleY" size="mini" @input="$attr.setAttrs(vm, 'scaleY', $event)" @change="$emit('change')" />
      </label>
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">旋转</div>
      <label class="dsf-ppt-attrs-row__column">
        <el-input :value="rotation" :controls="false" size="mini" @input="$attr.setAttrs(vm, 'rotation', $event)" />
      </label>
    </div>
    <div class="dsf-ppt-attrs-row">
      <div class="dsf-ppt-attrs-row__title">透明</div>
      <label class="dsf-ppt-attrs-row__column">
        <el-slider :value="opacity" :min="0" :max="1" :step="0.000001" size="mini" @input="$attr.setAttrs(vm, 'opacity', $event)" />
      </label>
    </div>
  </ppt-attr-panel>
</template>

<script>
import PptAttrPanel from './PptAttrPanel';

export default {
  name: "BaseAttrsItem",
  inject: ['$attr'],
  components: {
    PptAttrPanel
  },
  data() {
    return {
      vm: this,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      scaleX: 1,
      scaleY: 1,
      rotation: 0,
      opacity: 1
    }
  },
  methods: {
    updateAttrs() {
      this.$attr.getAttr(['x', 'y', 'width', 'height', 'scaleX', 'scaleY', 'rotation', 'opacity'], this);
    }
  }
}
</script>