var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "dsf-ppt-aside" },
    [
      _c("dsf-virtual-scroll", [
        _c(
          "div",
          { staticClass: "dsf-ppt-aside-list" },
          [
            _vm._l(_vm.imgList, function (item, index) {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "context-menu",
                        rawName: "v-context-menu",
                        value: { params: { item: item }, data: _vm.treeMenu },
                        expression: "{params: {item}, data: treeMenu }",
                      },
                    ],
                    key: item.id,
                    ref: "item",
                    refInFor: true,
                    staticClass: "dsf-ppt-aside-list-item",
                    style: _vm.itemStyle,
                    attrs: { active: _vm.active.id === item.id },
                    on: {
                      click: function ($event) {
                        return _vm.itemClick(index)
                      },
                    },
                  },
                  [
                    item.thumbnail
                      ? _c("img", { attrs: { src: item.thumbnail, alt: "" } })
                      : _vm._e(),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.imgList.length < _vm.$ppt.propsMax,
              expression: "imgList.length < $ppt.propsMax",
            },
          ],
          staticClass: "dsf-ppt-aside-addbt addbt iconfont icon-add1",
          attrs: { title: "新建页面" },
          on: { click: _vm.addPage },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }