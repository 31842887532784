export default class PptITextEditor {
  width = 0;
  height = 0;
  scaleX = 1;
  scaleY = 1;
  rotation = 0;

  constructor(node) {
    this.node = node;
    this.template();
    let scaleX = node.scaleX() || 1;
    let scaleY = node.scaleY() || 1;
    let rotation = node.rotation();
    this.textarea.value = node.text();
    this.textarea.focus();
    this.textarea.style.transform = `rotateZ(${rotation}deg) scaleX(${scaleX}) scaleY(${scaleY})`;
    this.textarea.style.fontSize = node.fontSize() + 'px';
    this.textarea.style.lineHeight = node.lineHeight();
    this.textarea.style.fontFamily = node.fontFamily();
    this.textarea.style.textAlign = node.align();
    this.textarea.style.caretColor = node.fill();

    this.#updateSize();
    this.#setStyle();

    node.fire('focus', this);
    node.getStage().fire('focus', this);

    setTimeout(() => {
      this.destroy = this.#destroy.bind(this);
      this.keydown = this.#keydown.bind(this);
      this.change = this.#change.bind(this);

      this.textarea.onkeydown = this.keydown;
      this.textarea.oninput = this.change;
      window.addEventListener('click', this.destroy);
    });
  }

  template() {
    let $el = document.createElement('div');
    $el.classList.add('dsf-ppt-konva-textarea');
    $el.onclick = e => {
      e.preventDefault();
      e.stopPropagation();
    }

    let textarea = document.createElement('textarea');
    $el.appendChild(textarea);

    this.textarea = textarea;
    this.$el = $el;
    this.$box = this.node.getStage().content.parentElement;
    this.$box.appendChild($el);
  }

  // 插入值
  insertText(text) {
    let index = this.textarea.selectionStart;
    let end = this.textarea.selectionEnd;
    let value = this.textarea.value;
    value = [value.substring(0, index), text, value.substring(end)].join('');
    this.textarea.value = value;
    this.textarea.focus();
    index += text.length;
    this.textarea.setSelectionRange(index, index);
    this.#change();
  }

  #setStyle() {
    let node = this.node;
    this.textarea.style.width = node.width() - node.padding() * 2 + 'px';
    this.textarea.style.height = node.height() - node.padding() * 2 + 'px';
  }

  // eslint-disable-next-line no-dupe-class-members
  #updateSize() {
    // 计算此时的坐标
    let node = this.node;
    let textPosition = node.absolutePosition();
    let top = textPosition.y;
    let left = textPosition.x;
    this.textarea.style.top = top + 'px';
    this.textarea.style.left = left + 'px';
  }

  // eslint-disable-next-line no-dupe-class-members
  #destroy(e) {
    if ($(e.target).closest('.dsf-ppt-metadata').get(0)) {
      return
    }
    this.node.fire('blur', this);
    this.node.getStage().fire('blur', this);
    window.removeEventListener('click', this.destroy);
    this.node.show();
    this.$box.removeChild(this.$el);
    for (let k in this) {
      this[k] = null;
    }
  }

  // eslint-disable-next-line no-dupe-class-members
  #keydown(e) {
    // esc键退出编辑
    if (e.keyCode === 27) {
      this.#destroy();
    }
  }

  // eslint-disable-next-line no-dupe-class-members
  #change() {
    this.node.text(this.textarea.value);
    this.#setStyle();
    this.node.fire('change');
  }
}