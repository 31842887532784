var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "dsf-ppt-main" },
    [
      !_vm.multiple ? _c("ppt-shape") : _vm._e(),
      _c(
        "div",
        { staticClass: "dsf-ppt-main-canvas" },
        [
          _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
            _vm.showScaleTips
              ? _c("span", { staticClass: "scale-tips" }, [
                  _vm._v(_vm._s(_vm.scale) + "%"),
                ])
              : _vm._e(),
          ]),
          _vm.$ppt.showMousePoint || _vm.ctrlKeyDown
            ? [
                _c("div", {
                  staticClass: "mouse-point __x",
                  style: { left: _vm.mousePoint.x + "px" },
                  attrs: {
                    p: Math.floor(
                      ((_vm.mousePoint.x - 14 - _vm.rulerXOrigin) * 100) /
                        _vm.scale
                    ),
                  },
                }),
                _c("div", {
                  staticClass: "mouse-point __y",
                  style: { top: _vm.mousePoint.y + "px" },
                  attrs: {
                    p: Math.floor(
                      ((_vm.mousePoint.y - 14 - _vm.rulerYOrigin) * 100) /
                        _vm.scale
                    ),
                  },
                }),
              ]
            : _vm._e(),
          _vm.$ppt.showRuler || _vm.ctrlKeyDown
            ? [
                _c("div", {
                  staticClass: "canvas-point __x",
                  style: {
                    width: _vm.realWidth + 1 + "px",
                    left: _vm.rulerXOrigin + 14 + "px",
                  },
                }),
                _c("div", {
                  staticClass: "canvas-point __y",
                  style: {
                    height: _vm.realHeight + 1 + "px",
                    top: _vm.rulerYOrigin + 14 + "px",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "ruler __x", style: _vm.rulerXStyle },
                  [
                    _c(
                      "span",
                      { staticClass: "origin", style: _vm.rulerXOriginStyle },
                      [_vm._v("0")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "ruler __y", style: _vm.rulerYStyle },
                  [
                    _c(
                      "span",
                      { staticClass: "origin", style: _vm.rulerYOriginStyle },
                      [_vm._v("0")]
                    ),
                  ]
                ),
              ]
            : _vm._e(),
          _c(
            "div",
            {
              ref: "box",
              staticClass: "canvas-box",
              on: {
                mousemove: _vm.boxMousemove,
                wheel: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.onWheel.apply(null, arguments)
                },
              },
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "context-menu",
                    rawName: "v-context-menu",
                    value: { data: _vm.contextMenuList },
                    expression: "{data: contextMenuList}",
                  },
                ],
                ref: "canvas",
                staticClass: "canvas",
                style: _vm.canvasStyle,
              }),
            ]
          ),
          _c(
            "div",
            {
              ref: "xBar",
              staticClass: "scroll-bar __x",
              class: { isDown: _vm.isDownX },
            },
            [
              _c("div", {
                ref: "xThumb",
                style: _vm.scrollBarXStyle,
                on: { mousedown: _vm.xBarMousedown },
              }),
            ]
          ),
          _c(
            "div",
            {
              ref: "yBar",
              staticClass: "scroll-bar __y",
              class: { isDown: _vm.isDownY },
            },
            [
              _c("div", {
                ref: "yThumb",
                style: _vm.scrollBarYStyle,
                on: { mousedown: _vm.yBarMousedown },
              }),
            ]
          ),
        ],
        2
      ),
      _vm.multiple ? _c("ppt-shape", { staticClass: "multiple" }) : _vm._e(),
      _c("ppt-tool"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }