var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dsf-ppt-tabs" }, [
    _c(
      "header",
      _vm._l(_vm.paneList, function (pane) {
        return _c(
          "div",
          {
            key: pane.name,
            staticClass: "dsf-ppt-tabs__name",
            class: { active: pane.name === _vm.showPane },
            on: {
              click: function ($event) {
                _vm.showPane = pane.name
              },
            },
          },
          [_vm._v(" " + _vm._s(pane.title) + " ")]
        )
      }),
      0
    ),
    _c("main", [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }