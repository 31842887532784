var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "show-close": false,
        "close-on-click-modal": false,
        width: "400px",
        title: "配置",
        "custom-class": "dsf-ppt-config",
        "modal-append-to-body": "",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.form, size: "small", "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "图片宽(px)" } },
            [
              _c("el-input-number", {
                attrs: {
                  disabled: _vm.$ppt.propsDisabledConfig,
                  min: 10,
                  max: 4096,
                  controls: false,
                  "step-strictly": "",
                },
                model: {
                  value: _vm.form.width,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "width", $$v)
                  },
                  expression: "form.width",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图片高(px)" } },
            [
              _c("el-input-number", {
                attrs: {
                  disabled: _vm.$ppt.propsDisabledConfig,
                  min: 10,
                  max: 4096,
                  controls: false,
                  "step-strictly": "",
                },
                model: {
                  value: _vm.form.height,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "height", $$v)
                  },
                  expression: "form.height",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "导出格式" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.$ppt.propsDisabledConfig,
                    placeholder: "请选择",
                  },
                  model: {
                    value: _vm.form.mimeType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "mimeType", $$v)
                    },
                    expression: "form.mimeType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "PNG", value: "image/png" },
                  }),
                  _c("el-option", {
                    attrs: { label: "JPEG", value: "image/jpeg" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "导出质量" } },
            [
              _c("el-input-number", {
                attrs: {
                  disabled: _vm.$ppt.propsDisabledConfig,
                  min: 0,
                  max: 1,
                  step: 0.01,
                  precision: 2,
                  controls: false,
                  "step-strictly": "",
                },
                model: {
                  value: _vm.form.quality,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "quality", $$v)
                  },
                  expression: "form.quality",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          !_vm.callback
            ? _c(
                "dsf-button",
                {
                  attrs: { size: "small", type: "plain" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("取 消")]
              )
            : _vm._e(),
          !_vm.$ppt.propsDisabledConfig
            ? _c(
                "dsf-button",
                { attrs: { size: "small" }, on: { click: _vm.confirm } },
                [_vm._v("确 定")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }