import libExtend from '../libExtend';

let utils = {
  loadScript(cb, error) {
    if (window.Konva) {
      libExtend();
      cb?.();
    } else {
      dsf.http
        .importFiles([
          dsf.url.getWebPath("$/js/libs/konva/Konva.es5.min.js"),
          dsf.url.getWebPath("$/js/libs/fileSaver/FileSaver-2.0.4.min.js"),
          dsf.url.getWebPath("$/js/libs/jsZip/jszip-3.6.0.min.js"),
        ])
        .then(() => {
          libExtend();
          cb?.();
        })
        .catch(err => {
          dsf.error(err);
          dsf.layer.message("加载konva脚本出错", false);
          error?.(err);
        });
    }
  },
  loadImage(src) {
    return new Promise(function (resolve) {
      if (!src) {
        resolve(null);
        return;
      }
      let img = new Image();
      img.onload = function () {
        resolve(img);
      }
      img.onerror = function () {
        resolve(null);
      }
      img.src = src;
    });
  },
  base64toBlob(base64, type) {
    let arr = base64.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], {type: type || mime});
  },
  layerToBase64(layer, width, height, options, isAsync) {
    if (layer?.layer) {
      layer = layer.layer;
    }
    if (layer instanceof window.Konva.Layer) {
      layer = layer.clone();
    } else if (layer && Object.values(layer).length) {
      layer = window.Konva.Node.create(layer, 'layer');
    } else {
      layer = null;
    }
    let container = document.createElement('canvas');
    let konva = new window.Konva.Stage({container, width, height});
    if (options && options.mimeType && options.mimeType !== 'image/png') {
      let bgLayer = new window.Konva.Layer();
      bgLayer.add(new window.Konva.Rect({
        x: 0,
        y: 0,
        width,
        height,
        fill: '#fff'
      }));
      konva.add(bgLayer);
    }
    layer && konva.add(layer);
    return isAsync ? konva.asyncToDataURL(options) : konva.toDataURL(options);
  },
  async download(that, fileName, $fabric, imgList) {
    let base64List = await this.createBase64ByImgs($fabric, imgList);
    let zip = new window.JSZip();
    // 创建images文件夹
    base64List.forEach((src, index) => {
      let base64 = src.split('base64,')[1];
      zip.file(`${index + 1}.jpeg`, base64, { base64: true });
    });
    let jsonData = this.getJsonData($fabric, imgList);
    zip.file('config.json', JSON.stringify({
      uploadHistoryList: that.uploadHistoryList,
      project: {
        width: $fabric.width,
        height: $fabric.height,
        imgList: jsonData
      }
    }), {
      comment: '项目配置文件'
    });
    zip.generateAsync({ type: "blob" }).then((blob) => {
      window.saveAs(blob, fileName + ".zip");
    });
  },
  uploadFile(...file) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("files", ...file);
      this.$http.post('file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).done(({ success, message, data }) => {
        if (success) {
          resolve(data[0]);
        } else {
          reject(message);
        }
      }).error(err => {
        reject(err);
      });
    });
  },
  /**
   * 起点(sx, sy)到终点(ex, ey)与垂直线的夹角
   * @param sx
   * @param sy
   * @param ex
   * @param ey
   * @returns {number}
   */
  pointToAngle(sx, sy, ex, ey) {
    let lengthX = ex - sx;
    let lengthY = ey - sy;
    let length = Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2));
    let angle = Math.asin(lengthX / length) * 180 / Math.PI;
    if (lengthY > 0) {
      angle = 180 - angle;
    } else if (lengthX < 0) {
      angle = 360 + angle;
    }
    return angle;
  },
  /**
   * 起点(sx, sy)到终点(ex, ey)的距离
   * @param sx
   * @param sy
   * @param ex
   * @param ey
   * @returns {number}
   */
  getLengthByPoint(sx, sy, ex, ey) {
    let lengthX = ex - sx;
    let lengthY = ey - sy;
    return  Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2));
  },
  /**
   * 导出根据data数据导出图片
   * @param project
   * @param dataList
   */
  exportByData(project, dataList) {
    return new Promise(function(resolve, reject) {
      let loading = dsf.layer.loading();
      utils.loadScript(() => {
        exportByData(project, dataList, loading).then(res => {
          resolve(res);
        })
          .catch(err => reject(err))
          .finally(() => {
            dsf.layer.closeLoading(loading);
          });
      }, err => {
        reject(err);
        dsf.layer.closeLoading(loading);
      });
    });
  },
  /**
   * 下载根据data数据导出的图片
   * @param project
   * @param dataList
   */
  downloadByData(project, dataList) {
    let loading = dsf.layer.loading();
    utils.loadScript(async () => {
      await downloadByData(project, dataList, loading);
      dsf.layer.closeLoading(loading);
    });
  }
}

class File extends Blob{
  #name = '';
  #lastModifiedDate = null;
  #lastModified = null;
  get name() {return this.#name}
  get lastModifiedDate() {return this.#lastModifiedDate}
  get lastModified() {return this.#lastModified}
  constructor(blobParts, options) {
    super(blobParts, options);
    let now = new Date();
    this.#lastModifiedDate = now;
    this.#lastModified = now.getTime();
  }
  setName(name) {this.#name = name}
}

function blob2file(blob, name) {
  try {
    return new window.File([blob], name, {
      type: blob.type
    });
  } catch (e) {
    if (blob.setName) {
      blob.setName(name);
      return blob;
    }
    blob.lastModifiedDate = new Date();
    blob.lastModified = blob.lastModifiedDate.getTime();
    blob.name = name;
    return blob;
  }
}

/**
 * 根据data数据导出图片
 * @param project
 * @param dataList
 * @param loading
 */
async function exportByData(project, dataList, loading) {
  if (!project) {
    dsf.layer.message('参数错误', false);
    return;
  }
  if (project.project) {
    project = project.project;
  }
  if (dsf.isObject(dataList)) {
    dataList = [dataList];
  }
  if (!dsf.isArray(dataList)) {
    dataList = false;
  } else if (!dataList.length) {
    dsf.layer.message('请给出导出所需数据', false);
    return;
  }
  let {width, height, mimeType, quality, img, imgList} = project;
  let suffix = mimeType === 'image/png' ? '.png' : '.jpeg';
  let options = {mimeType, quality};
  if (img) {
    let layer = img;
    if (!dataList) {
      let blob = await exportItemsByData({layer, width, height, options});
      return blob2file(blob, `ppt${suffix}`, mimeType);
    } else {
      let res = [];
      for (let i in dataList) {
        loading.text = `生成第${+i + 1}张图...`;
        let blob = await exportItemsByData({layer, width, height, options}, dataList[i]);
        res.push(blob2file(blob, `${+i + 1}${suffix}`, mimeType));
      }
      return res;
    }
  }
  if (!imgList.length) {
    dsf.layer.message('没有图片可以导出', false);
    return;
  }
  if (!dataList) {
    let res = [];
    for (let i = 0, l = imgList.length; i < l; i++) {
      loading.text = `共${l}张图，正在生成第${i + 1}张图...`;
      let layer = imgList[i];
      let blob = await exportItemsByData({layer, width, height, options}, dataList[i]);
      res.push(blob2file(blob, `${i + 1}${suffix}`, mimeType));
    }
    return res;
  }
  let res = [];
  let n = 0, l = dataList.length * imgList.length;
  for (let i in dataList) {
    let item = {
      name: `第${+i + 1}条数据`,
      children: []
    }
    for (let j in imgList) {
      loading.text = `共${l}张图，正在生成第${++n}张图...`;
      let layer = imgList[j];
      let blob = await exportItemsByData({layer, width, height, options}, dataList[i]);
      item.children.push(blob2file(blob, `${+j + 1}${suffix}`, mimeType));
    }
    res.push(item);
  }
  return res;
}

/**
 * 下载根据data数据导出的图片
 * @param project
 * @param dataList
 * @param loading
 */
async function downloadByData(project, dataList, loading) {
  if (!project) {
    dsf.layer.message('参数错误', false);
    return;
  }
  if (project.project) {
    project = project.project;
  }
  if (dsf.isObject(dataList)) {
    dataList = [dataList];
  }
  if (!dsf.isArray(dataList)) {
    dataList = false;
  } else if (!dataList.length) {
    dsf.layer.message('请给出导出所需数据', false);
    return;
  }
  let {width, height, mimeType, quality, img, imgList} = project;
  let suffix = mimeType === 'image/png' ? '.png' : '.jpeg';
  let options = {mimeType, quality};
  if (img) {
    let layer = img;
    if (!dataList) {
      let blob = await exportItemsByData({layer, width, height, options});
      window.saveAs(blob, `ppt${suffix}`);
    } else {
      let zip = new window.JSZip();
      for (let i in dataList) {
        loading.text = `生成第${+i + 1}张图...`;
        let blob = await exportItemsByData({layer, width, height, options}, dataList[i]);
        zip.file(`${+i + 1}${suffix}`, blob, {binary: true});
      }
      zip.generateAsync({type: "blob"}).then((blob) => {
        window.saveAs(blob, "ppt.zip");
      });
    }
    return
  }
  if (!imgList.length) {
    dsf.layer.message('没有图片可以导出', false);
    return;
  }
  let zip = new window.JSZip();
  if (!dataList) {
    for (let i = 0, l = imgList.length; i < l; i++) {
      loading.text = `共${l}张图，正在生成第${i + 1}张图...`;
      let layer = imgList[i];
      let blob = await exportItemsByData({layer, width, height, options}, dataList[i]);
      zip.file(`${i + 1}${suffix}`, blob, {binary: true});
    }
  } else {
    for (let i = 0, n = 0, l = dataList.length * imgList.length; i < l; i++) {
      let folder = zip.folder(`第${i + 1}条数据`);
      for (let j in imgList) {
        loading.text = `共${l}张图，正在生成第${++n}张图...`;
        let layer = imgList[j];
        let blob = await exportItemsByData({layer, width, height, options}, dataList[i]);
        folder.file(`${j + 1}${suffix}`, blob, {binary: true});
      }
    }
  }
  zip.generateAsync({type: "blob"}).then((blob) => {
    window.saveAs(blob, "ppt.zip");
  });
}

async function exportItemsByData({layer, width, height, options}, data) {
  if (data) {
    layer = JSON.parse(dsf.express.replace(JSON.stringify(layer), data));
  }
  let base64 = await utils.layerToBase64(layer, width, height, options, true);
  return utils.base64toBlob(base64, options.mimeType);
}

export default utils;