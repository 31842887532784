<template>
  <div ref="sliderBox" class="dsf-ppt-color-select-hue" @mousedown="mousedown">
    <span ref="thumb" class="slider" :style="sliderStyle"></span>
  </div>
</template>

<script>
import slider from '../slider';

export default {
  name: "Hue",
  mixins: [slider],
  data() {
    return {
      sliderType: 'y',
      top: 0
    }
  },
  computed: {
    sliderStyle() {
      return {
        top: this.top
      }
    }
  },
  created() {
    this.updatePoint();
  },
  methods: {
    updatePoint() {
      let {_hue} = this.$parent.color;
      this.top = _hue * 100 / 360 + '%';
      return this;
    },
    pointChange(x, y) {
      this.top = y + 'px';
      let {offsetHeight} = this.$refs.sliderBox;
      this.$parent.color.set('hue', y * 360 / offsetHeight);
      this.$parent.colorChange();
    }
  }
}
</script>