var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ppt-attr-panel", { attrs: { title: "描边" } }, [
    _c(
      "div",
      { staticClass: "dsf-ppt-attrs-row" },
      [
        _c("dsf-ppt-color-select", {
          attrs: { value: _vm.stroke },
          on: {
            input: function ($event) {
              return _vm.$attr.setAttrsByStr(_vm.vm, "stroke", $event)
            },
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("线宽")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c("el-input", {
            attrs: { value: _vm.strokeWidth, size: "mini" },
            on: {
              input: function ($event) {
                return _vm.$attr.setAttrs(_vm.vm, "strokeWidth", $event)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("边角")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c(
            "el-select",
            {
              attrs: {
                value: _vm.lineJoin,
                placeholder: "请选择",
                size: "mini",
              },
              on: {
                input: function ($event) {
                  return _vm.$attr.setAttrsByStr(_vm.vm, "lineJoin", $event)
                },
              },
            },
            _vm._l(_vm.lineJoinList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.text, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }