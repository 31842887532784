var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ppt-attr-panel",
    { attrs: { title: "背景填充" } },
    [_c("dsf-ppt-fill", { attrs: { node: _vm.$attr.activeShape } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }