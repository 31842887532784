<template>
  <header class="dsf-ppt-header">
    <div class="dsf-ppt-header-left">
      <dsf-button v-for="button in buttons" :key="button.id" :disabled="$ppt.isDesign" :title="button.text" :icon="button.icon" btn-style="icon-text" @click="button.handler()">
        {{ button.text }}
      </dsf-button>
      <i class="division iconfont icon-vertical_line"></i>
      <dsf-button v-for="button in buttons2" :key="button.id" :disabled="$ppt.isDesign" :title="button.text" :icon="button.icon" btn-style="icon-text" @click="button.handler()">
        {{ button.text }}
      </dsf-button>
    </div>
    <div class="dsf-ppt-header-right">
      <el-checkbox v-model="$ppt.showRuler" size="mini" border>刻度</el-checkbox>
      <el-checkbox v-model="$ppt.showMousePoint" size="mini" border>辅助线</el-checkbox>
    </div>
    <el-dialog v-if="!$ppt.isDesign" :visible.sync="dialogVisible" custom-class="dsf-ppt-help" width="800px" title="操作指南" append-to-body>
      <ppt-help />
    </el-dialog>
  </header>
</template>

<script>
import PptHelp from "./PptHelp";

export default {
  name: "PptHeader",
  components: {
    PptHelp
  },
  inject: ['$ppt'],
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  computed: {
    activeIndex() {
      return this.$ppt.activeIndex;
    },
    buttons() {
      let arr = [
        {
          text: "导出",
          id: "export",
          icon: "APIshuchu",
          handler: this.$ppt.export
        },
        {
          text: "导入配置",
          id: "import1",
          icon: "APIjieru",
          handler: this.$ppt.importLocal
        },
        // {
        //   text: "远端导入",
        //   id: "import2",
        //   icon: "yunduanxiazai",
        //   handler: this.$ppt.importServe
        // }
      ];
      if (this.$ppt.$listeners['save']) {
        arr.unshift({
          text: "保存",
          id: "save",
          icon: "baocun",
          handler: this.$ppt.save
        });
      }
      return arr;
    },
    buttons2() {
      let arr = [{
        text: "配置",
        id: "setting",
        icon: "shezhi",
        handler: this.$ppt.setting
      }];
      if (this.multiple) {
        let l = this.$ppt.imgList?.length || 0;
        if (this.$ppt.project && l < this.$ppt.propsMax) {
          arr.push({
            text: "新建页面",
            id: "addPage",
            icon: "fangkuai",
            handler: this.$ppt.addPage
          });
        }
        if (this.activeIndex > -1 && l > this.$ppt.propsMin) {
          arr.push({
            text: "删除此页",
            id: "removePage",
            icon: "shanchu",
            handler: this.$ppt.removePage
          });
        }
        if (this.$ppt.project && l > this.$ppt.propsMin) {
          arr.push({
            text: "删除所有",
            id: "removeAllPage",
            icon: "delete",
            handler: this.$ppt.removeAllPage
          });
        }
      }
      arr.push({
        text: "操作指南",
        id: "help",
        icon: "wenhao",
        handler: () => {
          this.dialogVisible = true;
        }
      })
      return arr;
    },
  }
}
</script>