var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-ppt-tool" },
    [
      _vm.attrsChange ? _c(_vm.attrName, { tag: "component" }) : _vm._e(),
      _c("ppt-layer-tree"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }