var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-ppt-fill" },
    [
      _c(
        "dsf-ppt-tabs",
        {
          model: {
            value: _vm.tabActive,
            callback: function ($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive",
          },
        },
        [
          _c(
            "dsf-ppt-tab-pane",
            { attrs: { name: "color", title: "纯色" } },
            [
              _c("dsf-ppt-color-select", {
                on: { change: _vm.setBgColor },
                model: {
                  value: _vm.color,
                  callback: function ($$v) {
                    _vm.color = $$v
                  },
                  expression: "color",
                },
              }),
            ],
            1
          ),
          _c(
            "dsf-ppt-tab-pane",
            { attrs: { name: "linear-gradient", title: "线性渐变" } },
            [
              _c("dsf-ppt-linear-gradual-select", {
                on: { change: _vm.setLinearGradual },
                model: {
                  value: _vm.linear,
                  callback: function ($$v) {
                    _vm.linear = $$v
                  },
                  expression: "linear",
                },
              }),
            ],
            1
          ),
          _c(
            "dsf-ppt-tab-pane",
            { attrs: { name: "radial-gradient", title: "径向渐变" } },
            [
              _c("dsf-ppt-radial-gradual-select", {
                on: { change: _vm.setRadialGradual },
                model: {
                  value: _vm.radial,
                  callback: function ($$v) {
                    _vm.radial = $$v
                  },
                  expression: "radial",
                },
              }),
            ],
            1
          ),
          _c(
            "dsf-ppt-tab-pane",
            { attrs: { name: "pattern", title: "图案" } },
            [
              _c("dsf-ppt-pattern", {
                on: { change: _vm.setPattern },
                model: {
                  value: _vm.pattern,
                  callback: function ($$v) {
                    _vm.pattern = $$v
                  },
                  expression: "pattern",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }