var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "dsf-virtual-scroll",
      { staticStyle: { color: "#333" }, attrs: { height: "60vh" } },
      [
        _c("p", { staticStyle: { "line-height": "4em" } }, [
          _c("span", { staticStyle: { "font-size": "22px" } }, [
            _c("strong", [_vm._v("快捷键")]),
          ]),
        ]),
        _c(
          "p",
          { staticStyle: { "text-indent": "2em", "margin-bottom": "10px" } },
          [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _c("strong", [
                _c(
                  "span",
                  { staticStyle: { "background-color": "rgb(242, 242, 242)" } },
                  [_vm._v(" 鼠标滚轮 ")]
                ),
              ]),
              _vm._v("：垂直滚动画布"),
            ]),
          ]
        ),
        _c(
          "p",
          { staticStyle: { "text-indent": "2em", "margin-bottom": "10px" } },
          [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _c("strong", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "18px",
                      "background-color": "rgb(242, 242, 242)",
                    },
                  },
                  [_vm._v(" Shift ")]
                ),
                _vm._v("+ "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "18px",
                      "background-color": "rgb(242, 242, 242)",
                    },
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          "background-color": "rgb(242, 242, 242)",
                        },
                      },
                      [_vm._v("鼠标滚轮 ")]
                    ),
                  ]
                ),
              ]),
              _vm._v("：横向滚动画布"),
            ]),
          ]
        ),
        _c(
          "p",
          { staticStyle: { "text-indent": "2em", "margin-bottom": "10px" } },
          [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _c("strong", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "18px",
                      "background-color": "rgb(242, 242, 242)",
                    },
                  },
                  [_vm._v(" Alt ")]
                ),
                _vm._v(" + "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "18px",
                      "background-color": "rgb(242, 242, 242)",
                    },
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          "background-color": "rgb(242, 242, 242)",
                        },
                      },
                      [_vm._v("鼠标滚轮 ")]
                    ),
                  ]
                ),
              ]),
              _vm._v("：缩放画布"),
            ]),
          ]
        ),
        _c(
          "p",
          { staticStyle: { "text-indent": "2em", "margin-bottom": "10px" } },
          [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _c("strong", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "18px",
                      "background-color": "rgb(242, 242, 242)",
                    },
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "18px",
                          "background-color": "rgb(242, 242, 242)",
                        },
                      },
                      [_vm._v("Shift ")]
                    ),
                  ]
                ),
              ]),
              _vm._v("：显示刻度和辅助线"),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }