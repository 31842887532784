<template>
  <div class="dsf-ppt-color-slider">
    <div ref="sliderBox" class="dsf-ppt-color-slider__bar">
      <div class="dsf-ppt-color-slider__bar--bg" :style="paneStyle" @click.self="addPoint"></div>
      <template v-for="(poi, i) in value">
        <div :key="i" class="dsf-ppt-color-slider__point" :style="{'left': poi.length + '%'}" @click.stop>
          <span v-show="poi.active" class="tips">{{ poi.length }}</span>
          <el-popover
            ref="popover"
            v-model="poi.show"
            :tabindex="1"
            placement="bottom"
            width="300"
            trigger="manual">
            <svg slot="reference" width="14" height="18" viewBox="-7 0 14 18">
              <polygon
                :fill="poi.color"
                points="0,2 -6,8 -6,17 6,17 6,8"
                @mousedown="pointMousedown(poi, $event)"
                @mouseup="showColorPop(poi, i, $event)"
              />
            </svg>
            <dsf-ppt-color-select v-model="poi.color" style="padding: 0" @change="$emit('change', value)" />
          </el-popover>
          <i v-if="value.length > 2" v-show="!poi.active" class="remove iconfont icon-guanbi2" title="删除" @click="removePoint(i)"></i>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import slider from '../slider';

export default {
  name: "ColorSlider",
  mixins: [slider],
  props: {
    value: {
      default: Array,
      required: true
    }
  },
  data() {
    return {
      activePoint: null
    }
  },
  computed: {
    paneStyle() {
      let str = this.value.map(({length, color}) => `${color} ${length}%`).join(',');
      return {
        'background': `linear-gradient(90deg, ${str})`
      }
    }
  },
  watch: {
    isDown(to) {
      if (!to) {
        this.$set(this.activePoint, 'active', false);
      }
    }
  },
  created() {
    this.activePoint = this.value?.[0];
  },
  methods: {
    addPoint(e) {
      let {offsetX} = e;
      let {offsetWidth} = this.$refs.sliderBox;
      let value = this.value;
      let length = Math.floor(offsetX * 100 / offsetWidth);
      let index = value.length;
      for (let i = 0, l = index; i < l; i ++) {
        if (value[i].length > length) {
          index = i;
          break;
        }
      }
      this.value.splice(index, 0, {
        length,
        color: '#fff'
      });
      this.$nextTick(() => {
        this.$emit('change', this.value);
      });
    },
    removePoint(i) {
      this.value.splice(i, 1);
      this.$nextTick(() => {
        this.$emit('change', this.value);
      });
    },
    pointMousedown(poi, e) {
      let {offsetX, offsetY, screenX, screenY, target} = e;
      while (true) {
        if (target.classList.contains('dsf-ppt-color-slider__point')) {
          this.__thumb = target;
          break;
        }
        target = target.parentElement;
      }
      this.___downTime = Date.now();
      this.___offsetX = offsetX;
      this.activePoint = poi;
      this.mousedown({
        offsetX, offsetY, screenX, screenY, target: this.__thumb
      });
      this.$set(poi, 'active', true);
    },
    showColorPop(poi, i, e) {
      if (Date.now() - this.___downTime > 300) return;
      let {offsetX} = e;
      if (this.___offsetX === offsetX && !poi.show) {
        let type = true;
        this.$set(poi, 'show', true);
        let fun = () => {
          if (type) {
            type = false;
            return;
          }
          window.removeEventListener('mouseup', fun);
          this.$set(poi, 'show', false);
        };
        window.addEventListener('mouseup', fun);
        this.$nextTick(() => {
          let el = this.$refs.popover[i].popperElm;
          let colorVm = el.firstElementChild.__vue__;
          el.onmouseup = e => {
            e.stopPropagation();
            _.each(colorVm.$refs, v => v.mouseup(e));
          };
        });
      }
    },
    pointChange(x) {
      let {offsetWidth} = this.$refs.sliderBox;
      this.$set(this.activePoint, 'length', Math.floor(x * 100 / offsetWidth));
      this.value.sort((a, b) => a.length - b.length);
      this.$nextTick(() => {
        this.$emit('change', this.value);
      });
    }
  }
}
</script>