var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ppt-attr-panel", { attrs: { title: "字体" } }, [
    _c(
      "div",
      { staticClass: "dsf-ppt-attrs-row" },
      [_c("dsf-ppt-fill", { attrs: { node: _vm.$attr.activeShape } })],
      1
    ),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("字号")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c("el-input", {
            attrs: { value: _vm.fontSize, controls: false, size: "mini" },
            on: {
              input: function ($event) {
                return _vm.$attr.setAttrs(_vm.vm, "fontSize", $event)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("行高")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c("el-input", {
            attrs: { value: _vm.lineHeight, controls: false, size: "mini" },
            on: {
              input: function ($event) {
                return _vm.$attr.setAttrs(_vm.vm, "lineHeight", $event)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("字体")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c(
            "el-select",
            {
              attrs: {
                value: _vm.fontFamily,
                placeholder: "请选择",
                size: "mini",
              },
              on: {
                input: function ($event) {
                  return _vm.$attr.setAttrsByStr(_vm.vm, "fontFamily", $event)
                },
              },
            },
            _vm._l(_vm.fontFamilyList, function (item) {
              return _c("el-option", {
                key: item,
                style: { "font-family": item },
                attrs: { label: item, value: item },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("样式")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c(
            "el-select",
            {
              attrs: {
                value: _vm.fontStyle,
                placeholder: "请选择",
                size: "mini",
              },
              on: {
                input: function ($event) {
                  return _vm.$attr.setAttrsByStr(_vm.vm, "fontStyle", $event)
                },
              },
            },
            _vm._l(_vm.fontStyleList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.text, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("装饰")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c(
            "el-select",
            {
              attrs: {
                value: _vm.textDecoration,
                placeholder: "请选择",
                size: "mini",
              },
              on: {
                input: function ($event) {
                  return _vm.$attr.setAttrsByStr(
                    _vm.vm,
                    "textDecoration",
                    $event
                  )
                },
              },
            },
            _vm._l(_vm.textDecorationList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.text, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("对齐")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c(
            "el-select",
            {
              attrs: { value: _vm.align, placeholder: "请选择", size: "mini" },
              on: {
                input: function ($event) {
                  return _vm.$attr.setAttrsByStr(_vm.vm, "align", $event)
                },
              },
            },
            _vm._l(_vm.alignList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.text, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c(
            "el-select",
            {
              attrs: {
                value: _vm.verticalAlign,
                placeholder: "请选择",
                size: "mini",
              },
              on: {
                input: function ($event) {
                  return _vm.$attr.setAttrsByStr(
                    _vm.vm,
                    "verticalAlign",
                    $event
                  )
                },
              },
            },
            _vm._l(_vm.verticalAlignList, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.text, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }