var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ppt-attr-panel", { attrs: { title: "基本属性" } }, [
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("坐标")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c("span", [_vm._v("X")]),
          _c("el-input", {
            attrs: { value: _vm.x, controls: false, size: "mini" },
            on: {
              input: function ($event) {
                return _vm.$attr.setAttrs(_vm.vm, "x", $event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c("span", [_vm._v("Y")]),
          _c("el-input", {
            attrs: { value: _vm.y, controls: false, size: "mini" },
            on: {
              input: function ($event) {
                return _vm.$attr.setAttrs(_vm.vm, "y", $event)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("尺寸")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c("span", [_vm._v("W")]),
          _c("el-input", {
            attrs: { value: _vm.width, size: "mini" },
            on: {
              input: function ($event) {
                return _vm.$attr.setAttrs(_vm.vm, "width", $event)
              },
              change: function ($event) {
                return _vm.$emit("change")
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("缩放")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c("span", [_vm._v("X")]),
          _c("el-input", {
            attrs: { value: _vm.scaleX, size: "mini" },
            on: {
              input: function ($event) {
                return _vm.$attr.setAttrs(_vm.vm, "scaleX", $event)
              },
              change: function ($event) {
                return _vm.$emit("change")
              },
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c("span", [_vm._v("Y")]),
          _c("el-input", {
            attrs: { value: _vm.scaleY, size: "mini" },
            on: {
              input: function ($event) {
                return _vm.$attr.setAttrs(_vm.vm, "scaleY", $event)
              },
              change: function ($event) {
                return _vm.$emit("change")
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("旋转")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c("el-input", {
            attrs: { value: _vm.rotation, controls: false, size: "mini" },
            on: {
              input: function ($event) {
                return _vm.$attr.setAttrs(_vm.vm, "rotation", $event)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "dsf-ppt-attrs-row" }, [
      _c("div", { staticClass: "dsf-ppt-attrs-row__title" }, [_vm._v("透明")]),
      _c(
        "label",
        { staticClass: "dsf-ppt-attrs-row__column" },
        [
          _c("el-slider", {
            attrs: {
              value: _vm.opacity,
              min: 0,
              max: 1,
              step: 0.000001,
              size: "mini",
            },
            on: {
              input: function ($event) {
                return _vm.$attr.setAttrs(_vm.vm, "opacity", $event)
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }