var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-ppt-shape" },
    [
      _vm._l(_vm.buttons, function (item, index) {
        return [
          _c(
            "el-tooltip",
            {
              key: index,
              attrs: {
                content: "插入" + item.text,
                effect: "light",
                placement: "left",
              },
            },
            [
              _c("DsfButton", {
                key: index,
                attrs: { icon: item.icon, "btn-style": "icon-text" },
                on: { click: item.handler },
              }),
            ],
            1
          ),
        ]
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showDialog,
            title: "选择图片",
            width: "800px",
            "modal-append-to-body": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("dsf-ppt-img-library", {
            ref: "library",
            staticStyle: { height: "500px" },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.sure },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }