import PptBgAttr from './PptBgAttr';
import PptITextAttr from './PptITextAttr';
import PptRichTextAttr from './PptRichTextAttr';
import PptImageAttr from './PptImageAttr';

export default {
  PptBgAttr,
  PptITextAttr,
  PptRichTextAttr,
  PptImageAttr
};