var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-form-item dsf-ppt-poster",
      class: _vm.getCss,
    },
    [
      _vm.showLabel
        ? _c(
            "label",
            { staticClass: "ds-form-label", style: _vm.getLabelWidthStyle() },
            [
              _vm._v(" " + _vm._s(_vm.label) + " "),
              _vm.dataCapion
                ? _c("i", {
                    staticClass:
                      "iconfont icon-bangzhuzhongxin ds-control-helper",
                    on: { click: _vm.showHelper },
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "ds-form-block",
          style: _vm.getFormItemBlockMarginLeft(),
        },
        [
          _vm.readOnly && _vm.simple
            ? _c(
                "dsf-button",
                {
                  attrs: {
                    disabled: !_vm.imgList.length,
                    title: "点击预览",
                    "btn-style": "icon-text",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.imagePreview(0)
                    },
                  },
                },
                [_vm._v(" 共" + _vm._s(_vm.imgList.length) + "张图片 ")]
              )
            : !_vm.imgList.length
            ? _c("div", { staticClass: "dsf-ppt-poster-imgList" }, [
                _vm.readOnly || _vm.disabled
                  ? _c(
                      "div",
                      {
                        staticClass: "dsf-ppt-poster-imgItem emit-data",
                        style: _vm.imgStyle,
                      },
                      [_c("span", [_vm._v("暂无图片")])]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "dsf-ppt-poster-imgItem emit-bt",
                        on: {
                          click: function ($event) {
                            return _vm.editor()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont icon-add",
                          style: _vm.imgStyle,
                        }),
                      ]
                    ),
              ])
            : !_vm.isFold
            ? _c(
                "div",
                { staticClass: "dsf-ppt-poster-imgList" },
                [
                  _vm._l(_vm.imgList, function (ref, i) {
                    var img = ref.img
                    return _c(
                      "div",
                      { key: i, staticClass: "dsf-ppt-poster-imgItem" },
                      [
                        _c("dsf-image", {
                          style: _vm.imgStyle,
                          attrs: { src: img },
                        }),
                        _c(
                          "div",
                          { staticClass: "dsf-ppt-poster-imgItem-mask" },
                          [
                            _c("dsf-button", {
                              attrs: {
                                title: "预览",
                                size: "small",
                                "btn-style": "2",
                                icon: "icon_yulan",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.imagePreview(i)
                                },
                              },
                            }),
                            !_vm.readOnly && !_vm.disabled
                              ? _c("dsf-button", {
                                  attrs: {
                                    title: "编辑",
                                    size: "small",
                                    "btn-style": "2",
                                    icon: "tianxie",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editor(i)
                                    },
                                  },
                                })
                              : _vm._e(),
                            !_vm.readOnly && !_vm.disabled
                              ? _c("dsf-button", {
                                  attrs: {
                                    title: "删除",
                                    size: "small",
                                    "btn-style": "2",
                                    icon: "shanchu",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove(i)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _vm.multiple &&
                  !_vm.isFold &&
                  !_vm.readOnly &&
                  !_vm.disabled &&
                  _vm.imgList.length < _vm.max
                    ? _c(
                        "div",
                        {
                          staticClass: "dsf-ppt-poster-imgItem emit-bt",
                          on: {
                            click: function ($event) {
                              return _vm.editor()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-add",
                            style: _vm.imgStyle,
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              )
            : _c(
                "div",
                {
                  staticClass: "dsf-ppt-poster-imgList",
                  class: { fold: _vm.imgList.length > 1 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "dsf-ppt-poster-imgItem" },
                    [
                      _c("dsf-image", {
                        style: _vm.imgStyle,
                        attrs: { src: _vm.imgList[0].img },
                      }),
                      _c(
                        "div",
                        { staticClass: "dsf-ppt-poster-imgItem-mask" },
                        [
                          _c("dsf-button", {
                            attrs: {
                              title: "预览",
                              size: "small",
                              "btn-style": "2",
                              icon: "icon_yulan",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.imagePreview(0)
                              },
                            },
                          }),
                          !_vm.readOnly && !_vm.disabled
                            ? _c("dsf-button", {
                                attrs: {
                                  title: "编辑",
                                  size: "small",
                                  "btn-style": "2",
                                  icon: "tianxie",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editor(0)
                                  },
                                },
                              })
                            : _vm._e(),
                          !_vm.readOnly && !_vm.disabled
                            ? _c("dsf-button", {
                                attrs: {
                                  title: "删除",
                                  size: "small",
                                  "btn-style": "2",
                                  icon: "shanchu",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.remove(0)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
          _vm._t("error", function () {
            return [
              _vm.errors.length > 0
                ? _c("div", { staticClass: "ds-error-text" }, [
                    _vm._v(" " + _vm._s(_vm.errorsMsg) + " "),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      !_vm.isDesign && !_vm.readOnly
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.showDialog,
                title: _vm.label,
                "close-on-click-modal": false,
                "custom-class": "dsf-ppt-poster-dialog",
                "modal-append-to-body": "",
                "append-to-body": "",
                fullscreen: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showDialog = $event
                },
                opened: function ($event) {
                  _vm.opened = true
                },
              },
            },
            [
              _vm.opened
                ? [
                    _vm.multiple && _vm.isFold
                      ? _c(
                          "dsf-ppts-setting",
                          _vm._b(
                            { on: { ready: _vm.pptReady, save: _vm.pptSave } },
                            "dsf-ppts-setting",
                            _vm.options,
                            false
                          )
                        )
                      : _c(
                          "dsf-ppt-setting",
                          _vm._b(
                            { on: { ready: _vm.pptReady, save: _vm.pptSave } },
                            "dsf-ppt-setting",
                            _vm.options,
                            false
                          )
                        ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }