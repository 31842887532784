var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dsf-ppt-tool-section" },
    [
      _c("div", { staticClass: "dsf-ppt-tool-title" }, [_vm._v("文本属性")]),
      _c(
        "dsf-virtual-scroll",
        { attrs: { height: "0" } },
        [
          _c("base-attrs-item", { ref: "base" }),
          _c("font-attrs-item", { ref: "font" }),
          _c("shadow-attrs-item", { ref: "shadow" }),
          _c("stroke-attrs-item", { ref: "stroke" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }