import { render, staticRenderFns } from "./PptAside.vue?vue&type=template&id=25bf868f&"
import script from "./PptAside.vue?vue&type=script&lang=js&"
export * from "./PptAside.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("I:\\web-project\\dream\\tools\\dsf-product-ppt\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25bf868f')) {
      api.createRecord('25bf868f', component.options)
    } else {
      api.reload('25bf868f', component.options)
    }
    module.hot.accept("./PptAside.vue?vue&type=template&id=25bf868f&", function () {
      api.rerender('25bf868f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "pc/components/PptAside.vue"
export default component.exports