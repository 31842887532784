var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
    _vm.ready
      ? _c(
          "div",
          { staticClass: "ds-control dsf-ppt" },
          [
            _c("ppt-header"),
            _c(
              "section",
              { staticClass: "dsf-ppt-section" },
              [
                !_vm.isDesign && _vm.activeImg
                  ? _c("ppt-main", { ref: "main" })
                  : _vm._e(),
              ],
              1
            ),
            _c("ppt-footer"),
            !_vm.isDesign ? _c("ppt-config", { ref: "config" }) : _vm._e(),
            !_vm.isDesign && _vm.metadataList.length
              ? _c("ppt-metadata", { ref: "metadata" })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }