<template>
  <div class="dsf-ppt-shape">
    <template v-for="(item, index) in buttons">
      <el-tooltip :key="index" :content="'插入' + item.text" effect="light" placement="left">
        <DsfButton
          :key="index"
          :icon="item.icon"
          btn-style="icon-text"
          @click="item.handler"
        />
      </el-tooltip>
    </template>
    <el-dialog
      :visible.sync="showDialog"
      title="选择图片"
      width="800px"
      modal-append-to-body
      append-to-body>
      <dsf-ppt-img-library ref="library" style="height: 500px" />
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="showDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import utils from "../utils/utils";

export default {
  name: "PptShape",
  inject: ['$ppt'],
  data() {
    return {
      showDialog: false,
      buttons: [
        { text: '文本', icon: 'xingzhuang-wenzi', handler: this.addIText },
        { text: '富文本', icon: 'wenben', handler: this.addRichText },
        { text: '图片', icon: 'tupian', handler: this.addImage },
        { text: '矩形', icon: 'xingzhuang-juxing', handler: this.addRect },
        { text: '圆形', icon: 'xingzhuang-tuoyuanxing', handler: this.addCircle },
        { text: '椭圆', icon: 'danxuankuangxuanzhong', handler: this.addEllipse },
        { text: '三角形', icon: 'xingzhuang-sanjiaoxing', handler: this.addTriangle },
        { text: '多边形', icon: 'xingzhuang-xingxing', handler: this.addPolygon },
        { text: '线', icon: 'xian', handler: this.addLine },
        { text: '折线', icon: 'zuzhizhankai', handler: this.addPolyline }
      ]
    }
  },
  computed: {
    konva() {
      return this.$ppt.konva;
    },
    layer() {
      return this.$ppt.activeImg.layer;
    },
    project() {
      return this.$ppt.project;
    }
  },
  methods: {
    addIText() {
      let textNode = new Konva.IText({
        name: 'component',
        x: 10,
        y: 10,
        text: 'Hollow DSF',
        fontSize: 30,
        fontFamily: 'Calibri',
        fill: '#333',
        draggable: true
      });
      this.layer.add(textNode);
    },
    addRichText() {
      let richText = new Konva.RichText({
        name: 'component',
        x: 10,
        y: 10,
        width: 100,
        height: 100,
        draggable: true
      });
      this.layer.add(richText);
    },
    addImage() {
      this.showDialog = true;
    },
    async sure() {
      let res = this.$refs.library.yes();
      if (!res?.relativePath) {
        dsf.layer.message('请选择一张图片', false);
        return;
      }
      this.showDialog = false;
      let img = await utils.loadImage(res.relativePath);
      if (!img) return;
      let imgNode = new Konva.Image({
        name: 'component',
        x: 50,
        y: 50,
        image: img,
        width: img.width,
        height: img.height,
        draggable: true
      });
      this.layer.add(imgNode);
    },
    addRect() {
      dsf.layer.message('待开发', dsf.config.message.warning);
    },
    addCircle() {
      dsf.layer.message('待开发', dsf.config.message.warning);
    },
    addEllipse() {
      dsf.layer.message('待开发', dsf.config.message.warning);
    },
    addTriangle() {
      dsf.layer.message('待开发', dsf.config.message.warning);
    },
    addPolygon() {
      dsf.layer.message('待开发', dsf.config.message.warning);
    },
    addLine() {
      dsf.layer.message('待开发', dsf.config.message.warning);
    },
    addPolyline() {
      dsf.layer.message('待开发', dsf.config.message.warning);
    },
  }
}

function Transformer(node) {
  return new Konva.Transformer();
}

</script>