import BaseAttrsItem from "./items/BaseAttrsItem";
import FontAttrsItem from "./items/FontAttrsItem";
import ShadowAttrsItem from "./items/ShadowAttrsItem";
import StrokeAttrsItem from "./items/StrokeAttrsItem";
import FillAttrsItem from "./items/FillAttrsItem";

export default {
  inject: ['$ppt', '$pptMain'],
  provide() {
    return {
      $attr: this
    }
  },
  components: {
    BaseAttrsItem,
    FontAttrsItem,
    ShadowAttrsItem,
    StrokeAttrsItem,
    FillAttrsItem
  },
  data() {
    return {
      activeShape: null,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      scaleX: 1,
      scaleY: 1,
      rotation: 0,
      opacity: 1
    }
  },
  computed: {
    layer() {
      return this.$ppt.activeImg.layer;
    },
    project() {
      return this.$ppt.project;
    }
  },
  created() {
    this.activeShape = this.$pptMain.activeShape;
  },
  mounted() {
    this._updateAttrs();
    this.activeShape.on('dragend', this._updateAttrs);
    this.activeShape.on('transformend', this._updateAttrs);
  },
  beforeDestroy() {
    this.activeShape.off('dragend', this._updateAttrs);
    this.activeShape.off('transformend', this._updateAttrs);
  },
  methods: {
    getAttr(keys, vm = this) {
      if (!dsf.isArray(keys)) {
        keys = [keys];
      }
      keys.forEach(k => {
        vm[k] = this.activeShape[k]();
      });
    },
    setAttrs(vm, fn, key, val) {
      if (val === void 0) {
        val = key;
        key = fn;
      }
      val = parseFloat(val);
      vm[key] = val;
      this.activeShape[fn](val);
    },
    setAttrsByStr(vm, fn, key, val) {
      if (val === void 0) {
        val = key;
        key = fn;
      }
      vm[key] = val;
      this.activeShape[fn](val);
    },
    _updateAttrs() {
      _.each(this.$refs, vm => vm.updateAttrs());
    }
  }
}